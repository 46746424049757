let appType = 'app';

if (process.env.REACT_APP_BUILD_TYPE === 'widget') {
	require('./WidgetApp');
	appType = 'widget';
}
else if (process.env.REACT_APP_BUILD_TYPE === 'dev') {
	const params = new URLSearchParams(window.location.search);
	if (params.get("widgetDev") === "true") {
		require('./WidgetApp');
		appType = 'widget';
	}
	else {
		require('./AppShell');
	}
}
else {
	require('./AppShell');
}

export { appType };
