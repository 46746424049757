import { StripeSelfServiceButton, useCurrentUser } from '@cassini-app/react';
import { Button, Card, CardActions, CardContent, CardHeader, Typography, useTheme } from '@material-ui/core';
import { useResetSubscriptionState, useSubscriptionId } from 'Api/Plans';
import { FlowResult } from 'CancelWidget/types';
import React from 'react';

declare var productShuttle: {
	cancel: (args: {
		subscriptionId: string;
		apiKey: string;
		mode?: 'test';
		colorMode?: 'light' | 'dark'
	}) => Promise<FlowResult>;
};

interface ManageBillingProps {
}

export const ManageBilling: React.FunctionComponent<ManageBillingProps> = () => {
	const currentUser = useCurrentUser();
	const subscriptionQuery = useSubscriptionId();
	const roles = currentUser.data?.roles;
	const theme = useTheme();
	const resetSubscriptionState = useResetSubscriptionState();

	const cancel = async () => {
		if (subscriptionQuery.data?.subscriptionId) {
			const result = await productShuttle.cancel({
				subscriptionId: subscriptionQuery.data.subscriptionId,
				apiKey: `${process.env.REACT_APP_PRODUCT_SHUTTLE_API_KEY}`,
				colorMode: theme.palette.type ?? "dark"
			});
			if (result.status !== 'incomplete') {
				resetSubscriptionState();
			}
		}
	}

	return <>
		{(roles?.includes("billing_admin") || roles?.includes("workspace_owner")) &&
			<Card style={{ margin: "20px 10px", flex: '1 1 max(300px, calc(25% - 20px))' }}>
				<CardHeader title="Billing" />
				<CardContent>
					<ul>
						<li><Typography>Update credit card</Typography></li>
						<li><Typography>View billing history</Typography></li>
						<li><Typography>Cancel Account</Typography></li>
					</ul>
				</CardContent>
				<CardActions>
					<StripeSelfServiceButton>Manage Billing</StripeSelfServiceButton>
					{subscriptionQuery.data?.subscriptionId && <Button color="primary" onClick={cancel}>Cancel Account</Button>}
				</CardActions>
			</Card>
		}
	</>;
}