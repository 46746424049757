import React from 'react';
import { User, useUsersApi, DeleteDialog } from '@cassini-app/react';
import { Tooltip, IconButton } from '@material-ui/core';
import { TrashIcon } from '../Icons';

export const DeleteUserButton: React.FC<{ user: User, workspaceId: string | undefined }> = ({ user, workspaceId }) => {
	const { deleteUser } = useUsersApi();
	const [open, setOpen] = React.useState(false);
	return <>
		<Tooltip title="Delete User">
			<IconButton onClick={() => setOpen(true)}>
				<TrashIcon />
			</IconButton>
		</Tooltip>
		<DeleteDialog confirmText={user.email} open={open} onClose={() => setOpen(false)} onDelete={() => deleteUser(user.id, workspaceId)} />
	</>;
}