import { Form, FormTextField, ProgressButton, User, useRoles, FormCheckboxList } from '@cassini-app/react';
import { Typography } from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';

interface UserDetailProps {
	user: User;
	onSubmit: (user: User) => Promise<unknown>
	onCancel?: () => void;
	hideRoles?: boolean;
	submitLabel?: string;
	onSuccess?: () => void;
}

function toTitleCase(str: string) {
	return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

const schema = yup.object().shape({
	email: yup.string().email().required(),
	roles: yup.array().of(yup.string())
});

export const UserDetail: React.FunctionComponent<UserDetailProps> = ({ user, onSubmit, onCancel, hideRoles, submitLabel, onSuccess }) => {
	const roles = useRoles();
	const roleOptions = roles.data?.roles.map(r => ({ value: r, label: toTitleCase(r), defaultChecked: user.roles.some(ur => ur === r) })) ?? [];

	return <>
		<Form<User> initialValues={user} onSubmit={onSubmit} validationSchema={schema} onSuccess={onSuccess}>
			<FormTextField label="Email" name="email" fullWidth />
			{!hideRoles && <>
				<Typography>Roles</Typography>
				<FormCheckboxList name="roles" options={roleOptions} />
			</>}
			<div>
				<ProgressButton type="submit" variant="contained" color="primary" style={{ marginRight: 10 }}>{submitLabel ?? "Save"}</ProgressButton>
				{onCancel && <ProgressButton variant="outlined" onClick={onCancel}>Cancel</ProgressButton>}
			</div>
		</Form>
	</>;
}