import { useQueryString } from '@cassini-app/react';
import { Button, Card, CardActions, CardContent, Container, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useApiKeys, useConnectStripeAccount } from 'Api/Integrations';
import { BillingProvider } from 'CancelWidget/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { HowToIntegrate } from './HowToIntegrate';

interface StripConnectProps {

}

const storageKey = "stripe_oauth_state";

export const StripeConnect: React.FunctionComponent<StripConnectProps> = () => {
	const connectStripeAccount = useConnectStripeAccount();
	const connect = () => {
		const state = uuid();
		sessionStorage.setItem(storageKey, state);
		const url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&stripe_landing=login&state=${state}`;
		window.location.href = url;
	}
	const queryString = useQueryString();
	const code = queryString.get("code");
	const state = queryString.get("state");
	const apiKeysQuery = useApiKeys();
	const history = useHistory();

	React.useEffect(() => {
		if (code && state) {
			const stateMatches = state === sessionStorage.getItem(storageKey);
			sessionStorage.removeItem(storageKey);
			if (stateMatches) {
				connectStripeAccount(code).then(() => {
					history.push("/integrations/website");
				})
			}
		}
	}, [code, state, history, connectStripeAccount]);

	const loading = apiKeysQuery.isLoading || code !== null;
	const stripeKeys = apiKeysQuery.data?.filter(apiKey => apiKey.provider === BillingProvider.Stripe) ?? [];
	const hasKeys = stripeKeys.length > 0;
	const spacing: React.CSSProperties = { margin: '10px 0' };

	return <Container>
		{loading && <Skeleton style={{ height: 50, transform: "scale(1, 0.9)" }} />}
		{!loading && <>
			{!hasKeys && <Card style={spacing}>
				<CardContent>
					<Typography>You need to connect stripe to complete your account setup. This will allow us to</Typography>
					<ul>
						<li>
							<Typography>Load your current plans and discounts.</Typography>
						</li>
						<li>
							<Typography>Update customers subscriptions if they accept one of your offers.</Typography>
						</li>
						<li>
							<Typography>Cancel customer accounts if they don't accept an offer.</Typography>
						</li>
					</ul>
				</CardContent>
				<CardActions>
					<Button onClick={connect} color="primary">Connect Stripe</Button>
				</CardActions>
			</Card>}
			{hasKeys && <HowToIntegrate stripeKeys={stripeKeys} />}
		</>}
	</Container>;
}