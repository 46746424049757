import { CurrentUser, Footer, FullScreenLoginPage, Link, NewWorkspaceCard, PageWithMenu, Sidebar, SidebarLinkGroup, Topbar, useCassiniThemeConfiguration, useCurrentUser } from '@cassini-app/react';
import { Button, Container, IconButton, Typography } from '@material-ui/core';
import { CancelBuilder } from 'CancelBuilders/CancelBuilder';
import { UserSignOut } from 'Components/UserSignOut';
import { DateRangePages } from 'DateRangePages';
import { StripeConnect } from 'Integrations/StripeConnect';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import Account from './Account/Account';
import './App.css';
import { ReactComponent as MoonIcon } from './Icons/moon.svg';
import { ReactComponent as NotFoundIcon } from './Icons/notfound.svg';
import { ReactComponent as SunIcon } from './Icons/sun.svg';
import { ReactComponent as Loading } from './images/loading.svg';
import Logo from './images/logo.png';
import { useTranslations } from './locales/UseTranslations';
import ThanksPage from './Thanks/Thanks';
import { UsersPage } from './Users/UsersPage';
import "./WidgetApp";
import WorkspacesPage from './Workspaces/WorkspacesPage';

const AuthorizedRoute: React.FunctionComponent<{ isAuthorized: boolean, unauthorized?: React.ReactNode, user: CurrentUser | null | undefined } & RouteProps> = ({ isAuthorized, children, unauthorized, user, ...routeProps }) => {
	const history = useHistory();
	if ((unauthorized !== undefined && !isAuthorized) || user === undefined) {
		return <>{unauthorized}</>;
	}
	return <Route {...routeProps}>
		{isAuthorized && <>{children}</>}
		{!isAuthorized && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flex: 1 }}>
			<Typography>You are not authorized to view this page.</Typography>
			<Button color="primary" variant='contained' onClick={() => history.push("/")}>Go to the dashboard</Button>
		</div>}
	</Route>
}

export const App = () => {
	const currentUser = useCurrentUser();
	const roles = currentUser.data?.roles ?? [];
	const isSiteAdmin = roles.includes("site_admin");
	const isWorkspaceAdmin = isSiteAdmin || roles.includes("workspace_admin");
	const text = useTranslations();
	const { setThemeType, type } = useCassiniThemeConfiguration();

	const sidebarLinks: SidebarLinkGroup[] = [
		{
			name: "",
			links: [
				{
					text: text('dashboard'),
					to: "/",
					exact: true
				},
				{
					text: 'History',
					to: "/history",
					exact: true
				}
			]
		},
		{
			name: "Configuration",
			links: [
				{
					text: "Reasons",
					to: "/build/reasons",
					exact: true
				},
				{
					text: "Offers",
					to: "/build/offers",
					exact: false
				},
				{
					text: "Customize",
					to: "/build/customize",
					exact: true
				},
				{
					text: "Preview Cancel Widget",
					to: "/build/widget",
					exact: true
				},
				{
					text: "Integration",
					to: "/integrations/website",
					exact: true
				}
			],
		}
	];

	const accountLinks: SidebarLinkGroup = {
		name: "Account",
		links: []
	}
	if (isWorkspaceAdmin) {
		accountLinks.links.push({
			text: text('Users'),
			to: "/users",
		});
	}
	if (isSiteAdmin) {
		accountLinks.links.push({
			text: text('Workspaces'),
			to: "/workspaces",
		})
	}
	if (accountLinks.links.length > 0) {
		sidebarLinks.push(accountLinks);
	}

	return (
		<Switch>
			<Route exact path="/login">
				<FullScreenLoginPage config={{ registration: { creditCard: "display", showWorkspace: true } }} img={Logo} />
				{currentUser.data && <Redirect to="/" />}
			</Route>
			<Route>
				<div>
					{currentUser.data === null && <Redirect to="/login" />}
					{currentUser.data === undefined && <div style={{ width: '100vw', height: '100vh', display: 'flex', backgroundColor: '#3F51B5', stroke: '#000A68' }}><Loading /></div>}
					{currentUser.data !== undefined && <PageWithMenu>
						<Topbar>
							<div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
								<IconButton onClick={() => setThemeType(type === "dark" ? "light" : "dark")} style={{ fill: 'white' }}>
									{type === 'dark' && <MoonIcon style={{ transform: 'rotate(45deg)' }} />}
									{type !== 'dark' && <SunIcon />}
								</IconButton>
							</div>
						</Topbar>
						<Sidebar header={<></>} links={sidebarLinks} />
						<Switch>
							<Route exact path="/signout">
								<UserSignOut />
							</Route>
							<Route exact path="/account"><Account /></Route>
							<Route path="/build">
								<>
									<CancelBuilder />
								</>
							</Route>
							<AuthorizedRoute user={currentUser.data} isAuthorized={isWorkspaceAdmin} exact path="/users"><UsersPage /></AuthorizedRoute>
							<AuthorizedRoute user={currentUser.data} isAuthorized={isSiteAdmin} exact path="/workspaces/:workspaceId/users"><UsersPage /></AuthorizedRoute>
							<AuthorizedRoute user={currentUser.data} isAuthorized={isSiteAdmin} exact path="/workspaces"><WorkspacesPage /></AuthorizedRoute>
							<Route path="/workspaces/new"><NewWorkspaceCard /></Route>
							<Route path="/integrations/website"><StripeConnect /></Route>
							<Route path="/thanks"><ThanksPage /></Route>
							<Route path={["/", "/history"]} exact>
								<DateRangePages />
							</Route>
							<Route>
								<Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
									<NotFoundIcon style={{ maxHeight: 350 }} />
									<Typography variant="h4">Oops, we can't seem to find that page.</Typography>
									<Link to="/">Go to the dashboard</Link>
								</Container>
							</Route>
						</Switch>
						<Footer />
					</PageWithMenu>}
				</div>
			</Route>
		</Switch >
	);
};
