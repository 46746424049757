import { Link, useTheme } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

interface MarkdownViewerProps {
	onLinkClicked?: () => void;
	children?: string;
	onClick?: () => void;
	style?: React.CSSProperties;
	className?: string;
}

export const MarkdownViewer = ({ onLinkClicked, children, onClick, style, className }: MarkdownViewerProps) => {
	const customRenderers = {
		link: ({ href, children }: any) => {
			return <Link href={href} target="_blank" rel="noreferrer noopener" onClick={onLinkClicked}>{children}</Link>
		}
	}

	const theme = useTheme();
	return <div style={{ color: theme.palette.text.primary, cursor: onClick ? 'pointer' : undefined, ...style }} onClick={onClick} className={className}>
		<ReactMarkdown plugins={[gfm]} renderers={customRenderers} skipHtml={false}>{children ?? ""}</ReactMarkdown>
	</div>
}