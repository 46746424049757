import { FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectProps } from '@material-ui/core';
import React from 'react';

interface DropdownProps {
	options?: { value: string | number, label: string }[]
	style?: React.CSSProperties;
}

export const Dropdown: React.FunctionComponent<DropdownProps & SelectProps & FormControlProps> = ({ label, options, children, style, ...props }) => {
	return <FormControl variant="outlined" style={{ margin: "10px 4px", ...style }} {...props}>
		<InputLabel>{label}</InputLabel>
		<Select label={label} {...props} >
			{options && options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
			{children}
		</Select>
	</FormControl>
}