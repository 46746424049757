import { v4 as uuid } from 'uuid';
import { ColorMode, ConditionOperator, ConditionType, DisplayTime, FlowOptions, Offer, OfferType, Phrase, Reason } from "./types";

export const DefaultResaons: Reason[] = [
	{
		name: "Too Expensive",
		archived: false,
		id: 'reason.b7643679-4968-4433-b705-7d732e8da798',
		order: 0
	},
	{
		name: "No Longer Needed",
		archived: false,
		id: 'reason.ba603603-09db-400a-b783-58bdf6077c1c',
		order: 1
	},
	{
		name: "Not sure how to use the product",
		archived: false,
		id: 'reason.2629bae4-6fdf-4bde-b0be-cc9ee25e30a4',
		order: 2
	},
	{
		name: "Switching to another product",
		archived: false,
		id: 'reason.738b9ae3-a449-44e2-8a7a-554e7d88d461',
		order: 3
	},
	{
		name: "Technical Issues",
		archived: false,
		id: 'reason.bcee16fe-6471-4a8b-95e1-eda24511c9a2',
		order: 4
	},
	{
		name: "Missing Features",
		archived: false,
		id: 'reason.e247374b-52db-4db9-b2ef-a487a15f637f',
		order: 5
	},
	{
		name: "Other (please explain)",
		archived: false,
		id: 'reason.ecde0da9-6c8f-4f47-a917-739ee6e02b84',
		order: 6
	}
]

export function getDefaultOffers() {
	const offers: Offer[] = [];
	const extendId = `offer.${uuid()}`;
	offers.push({
		type: OfferType.ExtendTrial,
		name: "Extend Trial",
		id: extendId,
		attributes: {
			length: 14
		},
		displayTime: DisplayTime.BeforeReason,
		details: "Do you need more time to try things out? We'll give you another 2 weeks!",
		enabled: true,
		confirmationMessage: 'This will extend your trial for 2 more weeks.',
		conditions: [
			{
				type: ConditionType.Offer,
				operator: ConditionOperator.Exclude,
				ids: [extendId],
				id: uuid()
			}
		]
	});

	const pauseId = `offer.${uuid()}`;
	offers.push({
		type: OfferType.Pause,
		name: "Pause for 6 months",
		id: pauseId,
		attributes: {
			length: 180,
			pausePlanId: 'pausedPlan'
		},
		displayTime: DisplayTime.BeforeReason,
		details: 'Pause your account for 6 months.\n* We\'ll keep all of your data safe.\n* After 6 months we\'ll automatically reactivate your account.',
		enabled: true,
		confirmationMessage: "Your subscription will be paused at the end of this billing cycle and remain paused for 6 months. You can reactivate at any point by switching back to an active plan.",
		conditions: []
	});

	offers.push({
		type: OfferType.Text,
		name: "Contact Support",
		id: `offer.${uuid()}`,
		displayTime: DisplayTime.AfterReason,
		details: "If your plan isn't meeting your needs please contact our sales team so we can help",
		enabled: true,
		confirmationMessage: "Call us at [555-0122](tel:555-0122) or email [contact@example.com](mailto:contact@example.com)",
		conditions: []
	});

	return offers;
}

export const DefaultPhrases: Phrase[] = [
	{ key: 'recoverMessage', text: "Before you cancel, are you interested in any of these special offers?" },
	{ key: 'welcomeMessage', text: "What would you like to do?" },
	{ key: 'reasonMessage', text: "**Would you mind letting us know why?** Your feedback will help us make a better product." },
	{ key: 'moreInfoPlaceholder', text: "Is there anything else you'd like to tell us?" },
	{ key: 'nevermindButton', text: "Nevermind" },
	{ key: 'cancelConfirmationTitle', text: "Cancel My Account" },
	{ key: 'cancelConfirmationDetails', text: "* Your subscription will be cancelled at the end of your billing cycle.\n* Your data will be deleted." },
	{ key: 'couponConfirmation', text: "Apply Discount" },
	{ key: 'extendTrialConfirmation', text: "Extend Trial" },
	{ key: 'pauseConfirmation', text: "Pause Subscription" },
	{ key: 'planConfirmation', text: "Change Plan" },
	{ key: 'backButton', text: "Back" },
	{ key: 'done', text: "Done" }
];

export const DefaultOptions: FlowOptions = {
	phrases: DefaultPhrases,
	style: {
		color: '#3f51b5',
		colorMode: ColorMode.Auto
	},
	getText: (key) => {
		return DefaultPhrases.find(p => p.key === key)?.text;
	}
}