import { Form, FormCheckboxList, ProgressButton } from '@cassini-app/react';
import { Button, Card, CardActionArea, CardActions, CardContent, Container, Divider, FormControlLabel, Switch, Typography, useTheme } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { useSaveOffer, useUpdateEnabledOffers } from 'Api/Offers';
import { getDefaultOffers } from 'CancelWidget/Defaults';
import { OfferView } from 'CancelWidget/OfferView';
import { Offer } from 'CancelWidget/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';

interface OfferSelectorProps {
	offers: Offer[] | undefined;
}

function sortOffers(a: Offer, b: Offer) {
	if (a.enabled === b.enabled) {
		return a.name.localeCompare(b.name);
	}
	if (a.enabled) {
		return -1;
	}
	return 1;
}

export const OfferSelector: React.FunctionComponent<OfferSelectorProps> = ({ offers: originalOffers }) => {
	const [offers, updateOffers] = useImmer(originalOffers);
	const history = useHistory();
	const theme = useTheme();
	const updateEnabledOffers = useUpdateEnabledOffers();

	React.useEffect(() => {
		updateOffers(() => originalOffers?.slice().sort(sortOffers));
	}, [originalOffers, updateOffers]);

	const toggleEnabled = (id: string) => {
		updateOffers(d => {
			const offer = d?.find(o => o.id === id);
			if (offer) {
				offer.enabled = !offer.enabled
			}
		})
	};

	const changedOffers = offers?.filter(offer => {
		const originalOffer = originalOffers?.find(o => o.id === offer.id);
		if (originalOffer && originalOffer.enabled !== offer.enabled) {
			return true;
		}
		return false;
	}) ?? [];

	const cardSize: React.CSSProperties = { maxWidth: 500, flex: '1 1 300px', margin: theme.spacing(2) };
	const skeletonStyle: React.CSSProperties = { ...cardSize, height: 180, transform: 'scale(1)' }

	return <Container>
		<Alert color="info">
			<Typography>Create offers to give your customers an alternative to cancelling.</Typography>
		</Alert>
		<div style={{ position: 'sticky', top: 56, backgroundColor: theme.palette.background.default, zIndex: 1 }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button color="primary" style={{ margin: 5 }} onClick={() => history.push("/build/offers/new")}>Add Offer</Button>
				<Form onSubmit={() => updateEnabledOffers(changedOffers)} initialValues={{}} style={{ flex: 0 }} submitEnabledOnlyIfChanged={false}>
					<ProgressButton type="submit" color="primary" style={{ margin: 5 }} disabled={changedOffers.length === 0}>Save</ProgressButton>
				</Form>
			</div>
			<Divider style={{ margin: `${theme.spacing(3)}px 0` }} />
		</div>
		<div>
			<div style={{ display: 'flex', flexWrap: 'wrap', margin: `0px -${theme.spacing(2)}px` }}>
				{offers && <>
					{offers.map(o => <Card key={o.id} style={{ ...cardSize, border: `1px solid ${theme.palette.background.paper}`, borderRadius: 4, display: 'flex', flexDirection: 'column' }}>
						<CardActionArea onClick={() => history.push(`/build/offers/${o.id}`)} style={{ backgroundColor: theme.palette.background.default, flex: 1 }}>
							<CardContent style={{ height: '100%' }} >
								<OfferView title={o.name} details={o.details} />
							</CardContent>
						</CardActionArea>
						<CardActions>
							<FormControlLabel control={<Switch checked={o.enabled} onChange={() => toggleEnabled(o.id)} name="enabled" disabled={false} />} label="Enabled" />
						</CardActions>
					</Card>)}
					<div style={{ ...cardSize, height: 0 }}></div>
					<div style={{ ...cardSize, height: 0 }}></div>
				</>}
				{offers === undefined && <>
					<Skeleton style={skeletonStyle} />
					<Skeleton style={skeletonStyle} />
					<Skeleton style={skeletonStyle} />
				</>}
			</div>
			{offers?.length === 0 && <QuickAddOffers />}
		</div>
	</Container>;
}

function QuickAddOffers() {
	const theme = useTheme();
	const offers = React.useMemo(() => getDefaultOffers(), []);
	const saveOffer = useSaveOffer();

	const add = async ({ offerIds }: { offerIds: string[] }) => {
		const offersToAdd = offers.filter(o => offerIds.includes(o.id));
		await saveOffer(offersToAdd);
	}

	return <>
		<Typography color="textSecondary" style={{ fontStyle: 'italic', textAlign: 'center' }}>No Offers Created yet</Typography>
		<Typography variant="h5" style={{ marginTop: theme.spacing(4) }}>Quick Add Offers</Typography>
		<Form<{ offerIds: string[] }> initialValues={{ offerIds: offers.map(o => o.id) }} onSubmit={add} submitEnabledOnlyIfChanged={false}>
			<div style={{ margin: `0 ${theme.spacing(2)}px` }}>
				<FormCheckboxList name="offerIds" options={offers.map(o => ({ label: o.name, value: o.id }))} />
			</div>
			<ProgressButton style={{ width: 100 }} color="primary" type="submit">Add</ProgressButton>
		</Form>
	</>;
}