import React from 'react';
import { ReactComponent as DownMoveHandle } from './DownMoveHandle.svg';
import { ReactComponent as MoveHandle } from './MoveHandle.svg';
import { ReactComponent as UpDownMoveHandle } from './UpDownMoveHandle.svg';
import { ReactComponent as UpMoveHandle } from './UpMoveHandle.svg';

interface MoveHandleImgProps {
	style?: React.CSSProperties;
	variant?: "upDown" | "up" | "down";
}

export const MoveHandleImg: React.FunctionComponent<MoveHandleImgProps> = ({ style, variant }) => {
	if (variant === "upDown") {
		return <UpDownMoveHandle style={style} />
	}
	else if (variant === "up") {
		return <UpMoveHandle style={style} />
	}
	else if (variant === "down") {
		return <DownMoveHandle style={style} />
	}
	return <MoveHandle style={style} />
}