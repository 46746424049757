import React from 'react';
import { User, useSessionApi, useCurrentUser } from '@cassini-app/react';
import { DeleteUserButton } from './DeleteUserButton';
import { Tooltip, IconButton } from '@material-ui/core';
import { ImpersonateIcon } from '../Icons';

export const UserActions: React.FC<{ user: User, workspaceId: string | undefined }> = ({ user, workspaceId }) => {
	const { impersonate } = useSessionApi();
	const currentUser = useCurrentUser();
	return <>
		<DeleteUserButton user={user} workspaceId={workspaceId} />
		{currentUser.data?.roles.includes("site_admin") && currentUser.data?.id !== user.id && <Tooltip title="Impersonate User">
			<IconButton onClick={() => impersonate(user.id, workspaceId)}>
				<ImpersonateIcon />
			</IconButton>
		</Tooltip>}
	</>;
}