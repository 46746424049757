import { useTheme } from '@material-ui/core';
import React from 'react';
import DarkLogo from './bubble-dark.png';
import LightLogo from './bubble-light.png';

type BubbleImageProps = Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src">;

export const BubbleImage: React.FunctionComponent<BubbleImageProps> = (props) => {
	const theme = useTheme();
	return <img {...props} src={theme.palette.type === "dark" ? DarkLogo : LightLogo} alt="Bubble.io Logo" />
}