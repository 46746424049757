
export const currencyFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2 });

export const dateFormatter = new Intl.DateTimeFormat(undefined, { month: 'short', day: 'numeric' });
export const fullDateformatter = new Intl.DateTimeFormat(undefined, { month: 'short', day: 'numeric', year: 'numeric' });

export function formatValue(value?: number | string | Date) {
	if (value === undefined) {
		return undefined;
	}
	if (typeof value === "number") {
		return currencyFormatter.format(value);
	}
	if (typeof value === "string") {
		return value;
	}
	return dateFormatter.format(value);
}