import { Link } from '@cassini-app/react';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useApiKeys } from 'Api/Integrations';
import { useOffers } from 'Api/Offers';
import { useReasons } from 'Api/Reasons';
import React from 'react';
import { DashboardCharts } from './DashboardCharts';

interface DashboardPageProps {
	startDate: Date;
	endDate: Date
}

export const DashboardPage: React.FC<DashboardPageProps> = ({ startDate, endDate }) => {
	const offersQuery = useOffers();
	const reasonsQuery = useReasons();
	const apiKeysQuery = useApiKeys();

	const needsReasons = reasonsQuery.isSuccess && reasonsQuery.data.length === 0;
	const needsOffers = offersQuery.isSuccess && offersQuery.data.length === 0;
	const needsKeys = apiKeysQuery.isSuccess && apiKeysQuery.data.length === 0;
	const showAlert = needsReasons || needsOffers || needsKeys;

	return <>
		{showAlert && <Alert severity="error" style={{ margin: '10px 0' }}>
			<AlertTitle>
				<Typography>To complete your account setup:</Typography>
			</AlertTitle>
			<ul>
				{needsReasons && <li>
					<Typography>Add <Link to="/build/reasons">reasons</Link></Typography>
				</li>}
				{needsOffers && <li>
					<Typography>Add <Link to="/build/offers">offers</Link></Typography>
				</li>}
				{needsKeys && <li>
					<Typography><Link to="/integrations/website">Connect</Link> a Stripe account</Typography>
				</li>}
			</ul>
		</Alert>}
		<DashboardCharts startDate={startDate} endDate={endDate} />
	</>
}
