import Axios from "axios";
import { useQuery, useQueryClient } from "react-query";

export interface Plan {
	id: string;
	name: string;
}

export interface Coupon {
	id: string;
	name: string;
}

export async function getPlans() {
	const response = await Axios.get<{ plans: Plan[] }>(`provider/plans`);
	return response.data.plans;
}

export async function getCoupons() {
	const response = await Axios.get<{ coupons: Coupon[] }>(`provider/coupons`);
	return response.data.coupons;
}

export enum PlanTypes {
	PayAsYouGo,
	Monthly
};

async function getActivePlan() {
	const response = await Axios.get<{ planType: PlanTypes | null }>(`account/plans`)
	return response.data;
}

export function useActivePlanMutation() {
	const queryClient = useQueryClient();
	return async (planType: PlanTypes) => {
		const response = await Axios.post(`account/plans`, { planType });
		if (response.status === 200) {
			queryClient.setQueryData('activePlan', { planType });
			queryClient.resetQueries('subscriptionId');
		}
	}
}

export function useActivePlan() {
	return useQuery('activePlan', getActivePlan);
}

async function getSubscriptionId() {
	const response = await Axios.get<{ subscriptionId: string | null }>(`users/me/subscriptionId`);
	return response.data;
}

export function useSubscriptionId() {
	return useQuery('subscriptionId', getSubscriptionId);
}

export function useResetSubscriptionState() {
	const queryClient = useQueryClient();
	return () => {
		queryClient.resetQueries('subscriptionId');
		queryClient.resetQueries('activePlan');
	}
}