import { Container } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useOffers } from 'Api/Offers';
import { useOptions } from 'Api/Settings';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useReasons } from '../Api/Reasons';
import { CustomizeBuilder } from './CustomizeBuilder';
import { OfferBuilder } from './OfferBuilder';
import { OfferSelector } from './OfferSelector';
import { ReasonBuilder } from './ReasonBuilder';
import { WidgetPreview } from './WidgetPreview';

interface CancelBuilderProps {

}

export const CancelBuilder: React.FunctionComponent<CancelBuilderProps> = () => {

	const reasonsQuery = useReasons();
	const offersQuery = useOffers();
	const optionsQuery = useOptions();

	const reasons = reasonsQuery.data ?? [];
	const offers = offersQuery.data;
	const options = optionsQuery.data;

	return <>

		<Switch>
			<Route path="/build/offers/:id">
				<OfferBuilder offers={offers} reasons={reasons} />
			</Route>
			<Route exact path="/build/offers"><OfferSelector offers={offers} /></Route>
			<Route path="/build/reasons">
				{reasonsQuery.data && <ReasonBuilder initialReasons={reasons} />}
				{reasonsQuery.data === undefined && <Container>
					<Skeleton style={{ height: 100 }} />
					<Skeleton style={{ height: 100 }} />
					<Skeleton style={{ height: 100 }} />
				</Container>}
			</Route>
			<Route path="/build/customize">
				<CustomizeBuilder options={options} />
			</Route>
			<Route path="/build/widget">
				<WidgetPreview offers={offers} />
			</Route>
		</Switch >
	</>;
}