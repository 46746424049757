import { Link, PagedQueryOptions, PagedTable, useCurrentUser, useInviteUser, usePagedUsers, User, useUsersApi } from '@cassini-app/react';
import { Button, Card, CardContent, CardMedia, Container, TableCell, TableRow, Typography } from '@material-ui/core';
import { useTranslations } from 'locales/UseTranslations';
import { MUIDataTableColumnDef } from "mui-datatables";
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { UserActions } from './UserActions';
import { UserDetail } from './UserDetail';

function formatDate(date: string | undefined) {
	if (date) {
		return Intl.DateTimeFormat().format(new Date(date));
	}
	return "";
}

export const UsersPage: React.FC<{}> = () => {
	const strings = useTranslations();
	const route = useRouteMatch<{ workspaceId?: string }>();
	const [inviting, setInviting] = React.useState<boolean>(false);
	const [queryOptions, setQueryOptions] = React.useState<PagedQueryOptions | undefined>(undefined);
	const inviteUser = () => setInviting(true);
	const closeInvite = () => setInviting(false);
	const sendInvite = useInviteUser();
	const currentUser = useCurrentUser();
	const { updateUser } = useUsersApi();
	const roles = currentUser.data?.roles ?? [];
	const isSiteAdmin = roles.includes("site_admin");
	const globalView = isSiteAdmin && route.params.workspaceId === undefined;
	const users = usePagedUsers({ workspaceId: route.params.workspaceId, options: queryOptions });
	const canShowActions = !isSiteAdmin || route.params.workspaceId !== undefined;
	const { enqueueSnackbar } = useSnackbar();

	const renderUserColumn = (render: (user: User) => React.ReactNode) => (dataIndex: number) => {
		const user = users.data?.items[dataIndex];
		return user === undefined ? <></> : render(user);
	}

	let columns: MUIDataTableColumnDef[] = [
		{
			name: "email", label: strings("email"), options: {
				customBodyRenderLite: renderUserColumn(user => <Link href={`mailto:${user.email}`}>{user.email}</Link>)
			}
		},
		{
			name: "createdDate", label: "Created", options: {
				customBodyRenderLite: renderUserColumn(user => <Typography>{formatDate(user.createdDate)}</Typography>),
				sort: true
			}
		},
		{
			name: "lastLoginDate", label: "Last Login", options: {
				customBodyRenderLite: renderUserColumn(user => <Typography>{formatDate(user.lastLoginDate)}</Typography>),
				sort: true
			}
		},
		{
			name: "actions", label: "Actions", options: {
				customBodyRenderLite: renderUserColumn(user => <UserActions user={user} workspaceId={route.params.workspaceId} />),
				sort: false
			}
		}
	].filter(col => (col.name !== "actions" && col.name !== "lastLoginDate") || canShowActions);

	const TableTitle = globalView ? <></> : <Button variant="contained" color="secondary" style={{ alignSelf: 'flex-end' }} onClick={inviteUser}>{strings("Send Invite")}</Button>

	const expandedRow = (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number; }) => {
		const user = users.data?.items[rowMeta.dataIndex];
		return <TableRow>
			<TableCell colSpan={rowData.length + 1}>
				{user ? <UserDetail key={`${user.email}${user.roles.reduce((p, c) => `${p}${c}`, '')}`} user={user} onSubmit={user => updateUser(user, route.params.workspaceId)} hideRoles={globalView} /> : <>Nothing</>}
			</TableCell>
		</TableRow>
	};

	const [rowsExpanded, setRowsExpanded] = React.useState<any[]>([]);

	const onRowExpansionChange = (_currentRowsExpanded: any[], allRowsExpanded: any[]) => {
		setRowsExpanded(allRowsExpanded?.map(r => r.index) ?? []);
	}

	return <Container>
		{inviting && <Card>
			<CardContent>
				<UserDetail
					user={{ email: '', id: '', roles: [], createdDate: '' }}
					onSubmit={user => sendInvite({ email: user.email, roles: user.roles })}
					onCancel={closeInvite}
					hideRoles={globalView}
					submitLabel="Send"
					onSuccess={() => { closeInvite(); enqueueSnackbar("Invitation Sent", { variant: 'success' }) }} />
			</CardContent>
		</Card>}
		{!inviting && <Card>
			<CardMedia>
				<PagedTable data={users} columns={columns} title={TableTitle} queryOptions={queryOptions} onQueryOptionsChange={setQueryOptions}
					options={{ renderExpandableRow: expandedRow, expandableRows: true, expandableRowsHeader: false, onRowExpansionChange, rowsExpanded }} />
			</CardMedia>
		</Card>}
	</Container>
}
