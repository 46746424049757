import { CassiniProvider } from "@cassini-app/react";
import { Backdrop } from "@material-ui/core";
import { getFlow } from "Api/Flow";
import { getMessagePassingOrigin, parseMessage, sendMessage } from "Api/MessagePassing";
import * as React from 'react';
import 'react-app-polyfill/ie11';
import * as ReactDOM from 'react-dom';
import { CancelDialog } from "./CancelWidget/CancelDialog";
import { ReactComponent as Loading } from './CancelWidget/loading.svg';
import { CancelConfig, ColorMode, Flow, FlowResult } from "./CancelWidget/types";

interface WidgetAppProps {

}

function widgetClose(result: FlowResult) {
	sendMessage({
		message: "close",
		args: result
	})
}

export const WidgetApp: React.FunctionComponent<WidgetAppProps> = () => {
	const [flow, setFlow] = React.useState<Flow | undefined>(undefined);
	const [config, setConfig] = React.useState<Partial<CancelConfig> | undefined>(undefined);

	React.useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			if (typeof event.data === "string" && event.origin === getMessagePassingOrigin()) {
				const data = parseMessage<CancelConfig>(event.data);
				if (data.message === "open") {
					setFlow(undefined);
					setConfig(data.args);
				}
			}
		}
		window.addEventListener("message", handleMessage);
		return () => {
			setConfig(undefined);
			window.removeEventListener("message", handleMessage);
			widgetClose({ status: 'incomplete' });
		}
	}, [setConfig, setFlow]);
	React.useEffect(() => {
		if (config && config.apiKey !== undefined && config.subscriptionId !== undefined && flow === undefined) {
			getFlow(config.apiKey, config.subscriptionId, config.mode).then(f => {
				if (config.colorMode === "light" || config.colorMode === "dark") {
					f.options.style.colorMode = config.colorMode === "light" ? ColorMode.Light : ColorMode.Dark;
				}
				setFlow(f)
			}).catch(error => {
				console.error(error);
				setConfig(undefined)
				widgetClose({ status: 'incomplete' });
			});
		}
	}, [config, flow])

	const close = (result: FlowResult) => {
		setConfig(undefined);
		widgetClose(result);
	}

	const open = config?.subscriptionId !== undefined;
	return <>
		<CassiniProvider>
			{flow && <CancelDialog flow={flow} open={open} onClose={close} mode={config?.mode} />}
			<Backdrop open={open && flow === undefined}><Loading /></Backdrop>
		</CassiniProvider>
	</>;
}

ReactDOM.render(<WidgetApp />, document.getElementById("root"));