import { Form, FormCheckbox, ProgressButton } from '@cassini-app/react';
import { Backdrop, Container, Typography, useTheme } from '@material-ui/core';
import { previewFlow, PreviewSettings } from 'Api/Flow';
import { CancelDialog } from 'CancelWidget/CancelDialog';
import { Flow, Offer } from 'CancelWidget/types';
import React from 'react';
import { ReactComponent as Loading } from "../CancelWidget/loading.svg";
import { FormChipList } from './Conditions/FormChipList';
import { SelectPlan } from './SelectPlan';

interface WidgetPreviewProps {
	offers: Offer[] | undefined;
}

export const WidgetPreview: React.FunctionComponent<WidgetPreviewProps> = ({ offers }) => {
	const [flow, setFlow] = React.useState<Flow | null>(null);
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();

	const onPreview = async (settings: PreviewSettings) => {
		setOpen(true);
		setFlow(null);
		const flow = await previewFlow(settings);
		setFlow(flow);
	}

	return <>
		<Container>
			<Typography variant="h5">Simulate Customer</Typography>
			<Form<PreviewSettings> initialValues={{ currentPlan: "", currentlyTrialing: false, currentlyPaused: false, previousOffers: [] }} onSubmit={onPreview} submitEnabledOnlyIfChanged={false}>
				<SelectPlan label="Current Plan" name="currentPlan" />
				<FormCheckbox label="Currently Trialing" name="currentlyTrialing" />
				<FormCheckbox label="Currently Paused" name="currentlyPaused" />
				<Typography color="primary">Offers Customer Has Already Used</Typography>
				<FormChipList name="previousOffers" options={offers?.map(o => ({ label: o.name, value: o.id })) ?? []} addLabel="Offers" />
				<ProgressButton type="submit" color="secondary" style={{ marginTop: 20 }}>Preview Cancellation Flow</ProgressButton>
			</Form>
		</Container>

		{flow && <CancelDialog flow={flow} open={open} onClose={() => setOpen(false)} mode="demo" userPreferredColorMode={theme.palette.type} />}
		<Backdrop style={{ zIndex: 100000 }} open={open && flow === null}><Loading /></Backdrop>
	</>;
}