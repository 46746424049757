import { Form, FormCheckboxList, ProgressButton } from '@cassini-app/react';
import { Button, Container, Divider, TextField, Typography, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSaveReasons } from 'Api/Reasons';
import { DefaultResaons } from 'CancelWidget/Defaults';
import { Reason } from 'CancelWidget/types';
import React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useImmer } from 'use-immer';
import { v4 as uuid } from 'uuid';
import { MoveHandleImg } from './MoveHandleImg';

interface ReasonBuilderProps {
	initialReasons: Reason[];
}

interface ReasonDraggableProps {
	reason: Reason;
	index: number;
	onNameChange: (value: string, id: string) => void;
	variant: "up" | "upDown" | "down"
}

function ReasonDraggable({ reason, index, onNameChange, variant }: ReasonDraggableProps) {
	const theme = useTheme();
	return <Draggable key={reason.id} draggableId={reason.id} index={index}>
		{(providedDraggable) => {
			const { onDragStart, ...handleProps } = providedDraggable.dragHandleProps!;
			return <div >
				<div ref={providedDraggable.innerRef} {...providedDraggable.draggableProps}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div {...handleProps} onDragStart={onDragStart as any} style={{ height: 25 }}>
							<MoveHandleImg variant={variant} style={{ width: 16, fill: theme.palette.text.primary }} />
						</div>
						<TextField value={reason.name} style={{ margin: theme.spacing(1) }} fullWidth onChange={(e) => onNameChange(e.target.value, reason.id)} />
					</div>
				</div>
			</div>
		}}
	</Draggable>
}

export const ReasonBuilder: React.FunctionComponent<ReasonBuilderProps> = ({ initialReasons }) => {
	const [reasons, updateReasons] = useImmer(initialReasons);
	const [newReason, setNewReason] = React.useState('');
	const saveReasons = useSaveReasons();

	const updated = reasons.length !== initialReasons.length || reasons.some(reason => {
		const initialReason = initialReasons.find(r => r.id === reason.id);
		if (initialReason) {
			return initialReason.archived !== reason.archived || initialReason.name !== reason.name || initialReason.order !== reason.order;
		}
		return true;
	})

	const save = async () => {
		await saveReasons(reasons);
	}

	const onDragEnd = (result: DropResult) => {
		const { destination, source, draggableId } = result;
		if (!destination) {
			return;
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		updateReasons(rs => {
			const existingReason = rs.find(r => r.id === draggableId);
			const sourceIndex = source.droppableId === "archivedReasonDroppable" ? source.index + rs.filter(r => !r.archived).length : source.index
			if (existingReason) {
				existingReason.archived = destination.droppableId === "archivedReasonDroppable";
				existingReason.order = destination.index;
			}
			function sortReasons(a: Reason, b: Reason) {
				if (a.archived && !b.archived) {
					return 1;
				}
				else if (!a.archived && b.archived) {
					return -1;
				}
				else {
					const difference = a.order - b.order;
					if (difference === 0) {
						if (a.id === draggableId) {
							return sourceIndex > (destination?.index ?? 0) ? -1 : 1;
						}
						else if (b.id === draggableId) {
							return sourceIndex > (destination?.index ?? 0) ? 1 : -1;
						}
					}
					return difference;
				}
			}
			const sortedReasons = rs.sort(sortReasons);
			for (let i = 0; i < sortedReasons.length; ++i) {
				sortedReasons[i].order = i;
			};
		})
	};

	const addReason = () => {
		updateReasons(newReasons => {
			newReasons.push({
				name: newReason,
				archived: false,
				id: `reason.${uuid()}`,
				order: reasons.filter(r => !r.archived).length
			});
		})
		setNewReason('');
	}

	const updateName = (value: string, id: string) => {
		updateReasons(newReasons => {
			const reason = newReasons.find(r => r.id === id);
			if (reason) {
				reason.name = value;
			}
		})
	}

	const archivedReasons = reasons.filter(r => r.archived);

	const addSuggestedReasons = ({ reasons }: { reasons: string[] }) => {
		updateReasons(newReasons => {
			for (const reason of reasons) {
				newReasons.push({
					archived: false,
					id: `reason.${uuid()}`,
					name: reason,
					order: newReason.length
				});
			}
		});
	}

	const theme = useTheme();
	const activeReasons = reasons.filter(r => !r.archived);

	return <Container style={{ marginBottom: 10 }}>
		<Alert color="info" style={{ marginBottom: 10 }}>
			<Typography>Add reasons for your customers to select when canceling their service. We recommend not adding more than 8 to keep your customers happy.</Typography>
		</Alert>
		<DragDropContext onDragEnd={onDragEnd}>
			<Form initialValues={{}} onSubmit={save} submitEnabledOnlyIfChanged={false}>
				<Typography variant="h5" style={{ margin: `${theme.spacing(2)}px 0` }}>Active Reasons For Cancellation</Typography>
				<Droppable droppableId='reasonDroppable'>
					{providedDroppable => {
						return <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
							{activeReasons.sort((a, b) => a.order - b.order).map((reason, index) => <ReasonDraggable variant={index === 0 ? "down" : "upDown"} key={reason.id} reason={reason} index={index} onNameChange={updateName} />)}
							{providedDroppable.placeholder}
						</div>
					}}
				</Droppable>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ width: 16, }}></div>
					<TextField fullWidth label="New Reason For Cancellation" value={newReason} onChange={e => setNewReason(e.target.value)} style={{ margin: theme.spacing(1) }} />
					<div style={{ width: 95 }}>
						<Button onClick={addReason} disabled={newReason.trim().length < 1}>Add</Button>
					</div>
				</div>
				{reasons.length > 0 && <>
					<Typography variant="h5" style={{ margin: `${theme.spacing(2)}px 0` }}>Archived Reasons For Cancellation</Typography>
					{archivedReasons.length === 0 && <Typography color="textSecondary" style={{ fontStyle: 'italic', margin: theme.spacing(2) }}>None</Typography>}
					<Droppable droppableId='archivedReasonDroppable'>
						{providedDroppable => {
							return <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
								{archivedReasons.sort((a, b) => a.order - b.order).map((reason, index) => <ReasonDraggable variant={index === archivedReasons.length - 1 ? "up" : "upDown"} key={reason.id} reason={reason} index={index} onNameChange={updateName} />)}
								{providedDroppable.placeholder}
							</div>
						}}
					</Droppable>
				</>}
				<ProgressButton disabled={!updated} type="submit" color="primary" fullWidth>Save</ProgressButton>
			</Form>
		</DragDropContext >
		{reasons.length === 0 && <>
			<Divider style={{ margin: `${theme.spacing(4)}px 0` }} />
			<div style={{ margin: theme.spacing(2) }}>
				<Typography variant="h5">Quick Add Reasons For Cancellation</Typography>
				<Form<{ reasons: string[] }> initialValues={{ reasons: DefaultResaons.map(r => r.name) }} onSubmit={addSuggestedReasons}>
					<div style={{ margin: `0 ${theme.spacing(2)}px` }}>
						<FormCheckboxList name="reasons" options={DefaultResaons.map(r => ({ label: r.name, value: r.name }))} />
					</div>
					<Button style={{ width: 100 }} color="primary" type="submit">Add</Button>
				</Form>
			</div>
		</>}
	</Container>;
}