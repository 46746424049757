import { Card, CardContent, CardHeader, Link, Typography, useTheme } from '@material-ui/core';
import { ApiKey } from 'CancelWidget/types';
import React from 'react';
import AddActionImg from '../images/bubble/add_action.png';
import ApiKeyImg from '../images/bubble/apikey.png';
import ConfigImg from '../images/bubble/config.png';

interface BubbleIntegrationProps {
	keys: ApiKey[];
}

export const BubbleIntegration: React.FunctionComponent<BubbleIntegrationProps> = ({ keys }) => {
	const theme = useTheme();
	const space = theme.spacing(2);
	const spacing = { margin: `${space}px 0` }
	const imgStyle: React.CSSProperties = { maxWidth: "100%" }
	return <div id="bubbleIntegration">
		<Card style={spacing}>
			<CardHeader title="Install The Plugin" />
			<CardContent>
				<Typography><Link href="https://bubble.io/plugin/product-shuttle-1610719255291x317373333490630660" target="_blank" rel="noreferrer noopener">Install</Link> the Product Shuttle plugin.</Typography>
			</CardContent>
		</Card>
		<Card style={spacing}>
			<CardHeader title="Add API Key" />
			<CardContent>
				<Typography style={{ marginBottom: space }}>Your API Key: {keys[0].id}</Typography>
				<img src={ApiKeyImg} alt="" style={imgStyle} />
			</CardContent>
		</Card>
		<Card style={spacing}>
			<CardHeader title="Use the Open Cancellation Dialog action" />
			<CardContent>
				<Typography style={{ marginBottom: space }}>Add the Open Cancellation Dialog to a workflow.</Typography>
				<img src={AddActionImg} alt="" style={imgStyle} />
			</CardContent>
		</Card>
		<Card style={spacing}>
			<CardHeader title="Add Stripe subscription id" />
			<CardContent>
				<Typography>You must provide the customer's Stripe subscription id.</Typography>
				<Typography style={{ marginBottom: space }}>If you are using Bubble's Stripe plugin you can get it from Current User &gt; Stripe Customer subscription &gt; Subscription_id.</Typography>
				<img src={ConfigImg} alt="" style={imgStyle} />
			</CardContent>
		</Card>
	</div>;
}