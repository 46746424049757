import { FormCheckboxList } from '@cassini-app/react';
import { Button, Chip, Menu, useTheme } from '@material-ui/core';
import { useField } from 'formik';
import produce from 'immer';
import React from 'react';

interface FormChipListProps {
	options: { value: string | number, label: string }[];
	name: string;
	addLabel?: string;
	style?: React.CSSProperties;
}

export const FormChipList: React.FunctionComponent<FormChipListProps> = ({ options, name, addLabel, style }) => {
	const theme = useTheme();
	const [field, , helpers] = useField<(string | number)[] | null>(name);
	const [list, setList] = React.useState(field.value ?? []);
	const remove = (value: string | number) => {
		const newList = produce(list, d => {
			d.splice(d.findIndex(v => v === value), 1);
		});
		helpers.setValue(newList);
		setList(newList);
	};

	const close = () => {
		setAnchorEl(null);
		setList(field.value ?? []);
	}

	const selected = options.filter(o => list.some(l => l === o.value));
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	return <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1, flexDirection: 'row', alignItems: 'center', ...style }}>
		{selected.map(o => <Chip key={o.value} label={o.label} onDelete={() => remove(o.value)} color="primary" style={{ margin: 5 }} />)}
		<Button variant="outlined" onClick={e => setAnchorEl(e.currentTarget)} style={{ minWidth: 0, margin: '10px 4px' }}>{addLabel ?? "+"}</Button>
		<Menu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={close}
		>
			<div style={{ margin: `0px ${theme.spacing(2)}px` }}>
				<FormCheckboxList name={name} options={options} />
				<Button color="primary" variant="contained" fullWidth onClick={close}>Done</Button>
			</div>
		</Menu>
	</div>
}