import { ThemeProvider, useTheme } from '@material-ui/core';
import produce from 'immer';
import React from 'react';

interface InlineSelectThemeProps {

}

export const InlineSelectTheme: React.FunctionComponent<InlineSelectThemeProps> = ({ children }) => {
	const theme = useTheme();
	const modifiedTheme = produce(theme, t => {
		if (t.overrides === undefined) {
			t.overrides = {};
		}
		if (t.overrides.MuiSelect === undefined) {
			t.overrides.MuiSelect = {};
		}
		if (t.overrides.MuiSelect.root === undefined) {
			t.overrides.MuiSelect.root = {};
		}
		t.overrides.MuiSelect.root = {
			...t.overrides.MuiSelect.root,
			paddingTop: 8,
			paddingBottom: 9
		}
	});

	return <ThemeProvider theme={modifiedTheme}>
		{children}
	</ThemeProvider>;
}