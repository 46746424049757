import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { LegendItem } from './LegendItem';
import { Line, LineChart, LineChartProps } from './LineChart';

interface LineChartCardProps {
	hideLegend?: boolean;
	title: string;
	lines: Line[] | null;
}

export const LineChartCard: React.FunctionComponent<LineChartCardProps & Omit<LineChartProps, "lines">> = ({ lines, hideLegend, title, children }) => {
	const showLegend = hideLegend !== true && lines;
	const hasData = lines === null || lines.reduce((prev, cur) => prev + cur.data.length, 0) > 0;
	return <Card style={{ marginTop: 10 }}>
		<CardHeader title={title} />
		<div style={{ height: 300, widows: 600, paddingBottom: (showLegend || lines === null) ? undefined : 10, position: 'relative' }}>
			{lines === null && <Skeleton variant="rect" style={{ width: '100%', height: '100%' }} />}
			<LineChart lines={lines ?? []} />
			{!hasData && <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Typography color="textSecondary">No data to display</Typography>
			</div>}
		</div>
		{showLegend && lines && <CardContent>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{lines.map((line, i) => <LegendItem key={line.id} value={line.id} color={line.color} />)}
			</div>
			{children}
		</CardContent>}
	</Card>;
}