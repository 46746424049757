import { Link } from '@cassini-app/react';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ResponsivePie } from '@nivo/pie';
import { useReasons } from 'Api/Reasons';
import { useSummaryReport } from 'Api/Reports';
import { FlowInteractionStatus } from 'CancelWidget/types';
import React from 'react';
import { CHART_COLORS, NOT_SELECTED, NOT_SELECTED_COLOR } from 'Utils/Constants';
import { formatValue } from 'Utils/Formatters';
import { groupBy } from 'Utils/GroupBy';
import { LegendItem } from './Charts/LegendItem';
import { Line } from './Charts/LineChart';
import { LineChartCard } from './Charts/LineChartCard';
import { useNivoTheme } from './Charts/UseNivoTheme';
import { SingleInfoCard } from './SingleInfoCard';

interface DashboardChartsProps {
	startDate: Date;
	endDate: Date;
}

function pad(value: number, pad: number, str: string) {
	return `${value}`.padStart(pad, str);
}

function formatDate(date: Date) {
	return `${date.getFullYear()}-${pad(date.getMonth() + 1, 2, '0')}-${pad(date.getDate(), 2, '0')}T00:00:00`;
}

interface RecordWithTimestamp {
	timestamp: string;
}
function getRecordDate(record: RecordWithTimestamp) {
	const date = new Date(record.timestamp);
	return formatDate(date);
}

function groupRecordsByDate<T extends RecordWithTimestamp>(records: T[], startDate?: Date, endDate?: Date) {
	if (records.length > 0) {
		let map = new Map<string, T[]>()
		const start = startDate ? new Date(startDate) : new Date(records[0].timestamp);
		const end = endDate ? new Date(endDate) : new Date();
		end.setHours(23, 59, 59, 0);
		for (let day = start; day < end; day.setDate(day.getDate() + 1)) {
			map.set(formatDate(day), []);
		}
		return groupBy(records, getRecordDate, map);
	}
	return groupBy(records, getRecordDate);
}

function createLine<T extends RecordWithTimestamp>(id: string, map: Map<string, T[]>, getValue: (items: T[]) => number, color: string) {
	return {
		id,
		color,
		data: Array.from(map).map(([key, items]) => {
			return { x: key, y: getValue(items) }
		})
	}
}

export const DashboardCharts: React.FunctionComponent<DashboardChartsProps> = ({ startDate, endDate }) => {
	const summary = useSummaryReport(startDate, endDate);
	const reasonsQuery = useReasons();

	const interactions = summary.data?.interactions;
	const savedRevenues = summary.data?.savedRevenues;

	const reasonColorPicker = React.useMemo(() => (value: string) => {
		const reasonKeys = [NOT_SELECTED].concat(reasonsQuery.data?.map(r => r.name) ?? []);
		const reasonColors = [NOT_SELECTED_COLOR].concat(CHART_COLORS);
		return reasonColors[reasonKeys.findIndex(r => r === value)]
	}, [reasonsQuery.data]);

	const savedRevenueLines = React.useMemo(() => {
		if (savedRevenues) {
			const map = groupRecordsByDate(savedRevenues, startDate, endDate);
			return [createLine("Revenue", map, items => items.reduce((prev, cur) => prev + cur.value, 0), CHART_COLORS[0])]
		}
		return null;
	}, [savedRevenues, startDate, endDate]);

	const [completedRecords, overall] = React.useMemo(() => {
		if (interactions) {
			const completedRecords = interactions.filter(record => record.status !== FlowInteractionStatus.Incomplete) ?? [];
			const dayMap = groupRecordsByDate(completedRecords, startDate, endDate);

			const overall = [
				createLine("Cancellations", dayMap, items => items.filter(r => r.offerId === null).length, CHART_COLORS[0]),
				createLine("Saves", dayMap, items => items.filter(r => r.offerId !== null).length, CHART_COLORS[1]),
				createLine("Completed Sessions", dayMap, items => items.length, CHART_COLORS[2]),
			];

			return [completedRecords, overall];
		}
		return [null, null]
	}, [interactions, startDate, endDate])

	interface PiePiece {
		id: string;
		value: number;
		color: string;
	};
	const [reasonsPie, reasonLines] = React.useMemo(() => {
		if (completedRecords && reasonsQuery.data) {
			const groupedReasons = groupBy(completedRecords, r => r.reasonId);
			const pieData = Array.from(groupedReasons).map(([key, group]) => {
				const reasonIndex = reasonsQuery.data?.findIndex(r => r.id === key) ?? -1;
				const id = reasonIndex >= 0 ? reasonsQuery.data![reasonIndex].name : NOT_SELECTED;
				return {
					id,
					value: group.length,
					color: reasonColorPicker(id)
				}
			});

			const lines = Array.from(groupedReasons).map(([key, groupInteractions], i) => {
				const reasonIndex = reasonsQuery.data?.findIndex(r => r.id === key) ?? -1;
				const groupDayMap = groupRecordsByDate(groupInteractions, startDate, endDate);
				const id = reasonIndex >= 0 ? reasonsQuery.data![reasonIndex].name : NOT_SELECTED;
				return createLine(id, groupDayMap, items => items.length, reasonColorPicker(id));
			})

			return [pieData, lines];
		}
		return [null as PiePiece[] | null, null as Line[] | null];
	}, [completedRecords, reasonsQuery.data, reasonColorPicker, startDate, endDate]);

	const nivoTheme = useNivoTheme();

	const sessionCount = interactions?.length ?? 0;
	const saveCount = completedRecords?.filter(r => r.offerId !== null).length ?? 0;
	const cancelCount = completedRecords?.filter(r => r.offerId === null).length ?? 0;
	const incompleteCount = (interactions?.length ?? 0) - (completedRecords?.length ?? 0);

	return (
		<>
			<div style={{ display: 'flex', flexWrap: "wrap", margin: '0 -5px' }}>
				<SingleInfoCard value={formatValue(savedRevenues?.reduce((prev, cur) => prev + cur.value, 0))} line1="Total Revenue Saved" to="" />
				<SingleInfoCard value={interactions === undefined ? undefined : saveCount > 0 ? `${Math.trunc(saveCount / sessionCount * 100)}%` : 0} line1="Save Rate" to="" />
				<SingleInfoCard value={interactions === undefined ? undefined : saveCount} line1="Customers Saved" to="" />
				<SingleInfoCard value={interactions === undefined ? undefined : cancelCount} line1="Customers Cancelled" to="" />
				<SingleInfoCard value={interactions === undefined ? undefined : incompleteCount} line1="Incomplete Attempts" to="" />
			</div>
			<LineChartCard title="Saved Revenue" lines={savedRevenueLines} hideLegend />
			<LineChartCard title="Cancellation Attempts" lines={overall}>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Link to="/history">See History</Link>
				</div>
			</LineChartCard>
			<LineChartCard title="Reasons For Cancellation" lines={reasonLines} />

			<Card style={{ overflow: 'visible', marginTop: 10 }}>
				<CardHeader title="Reason For Cancellation Distribution" />
				<div style={{ height: 300, widows: 600, position: 'relative', overflow: 'hidden' }}>
					{reasonsPie === null && <Skeleton variant="rect" style={{ width: '100%', height: '100%' }} />}
					<ResponsivePie
						data={reasonsPie ?? []}
						margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
						innerRadius={.5}
						padAngle={3}
						cornerRadius={0}
						colors={value => value.data.color}
						borderWidth={1}
						borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
						enableRadialLabels={false}
						sliceLabelsSkipAngle={10}
						sliceLabelsTextColor="#333333"
						theme={nivoTheme}
					/>
					{(reasonsPie?.length ?? 1) < 1 && <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Typography color="textSecondary">No data to display</Typography>
					</div>}
				</div>
				{reasonsPie && <CardContent>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{reasonsPie.map((value) => <LegendItem key={value.id} value={value.id} color={value.color} />)}
					</div>
				</CardContent>}
			</Card>
		</>
	);
}
