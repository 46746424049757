import { CassiniProvider } from '@cassini-app/react';
import { Analytics } from 'Analytics';
import { App } from 'App';
import { AxiosError } from 'axios';
import React from 'react';
import 'react-app-polyfill/ie11';
import * as ReactDOM from 'react-dom';

interface AppShellProps {

}

function isAxiosErrorLike(error: { isAxiosError?: any }): error is { isAxiosError: boolean } {
	if ('isAxiosError' in error && typeof error.isAxiosError === "boolean") {
		return true;
	}
	return false;
}

function isAxiosError(error: unknown): error is AxiosError {
	if (typeof error === 'object' && error && isAxiosErrorLike(error)) {
		return error.isAxiosError;
	}
	return false;
}

function shouldRetry(count: number, error: unknown) {
	if (isAxiosError(error) && error.response?.status === 400) {
		return false;
	}
	return count < 3;
}

export const AppShell: React.FunctionComponent<AppShellProps> = () => {
	return <CassiniProvider queryConfig={{ queries: { refetchOnWindowFocus: false, refetchInterval: false, retry: shouldRetry } }}>
		<Analytics />
		<App />
	</CassiniProvider>;
}

const root = document.getElementById('root');
if (root) {
	ReactDOM.render(<AppShell />, root);
}