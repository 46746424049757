import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './thanks.module.css';

interface ThanksPageProps {

}

const ThanksPage: React.FunctionComponent<ThanksPageProps> = () => {
	return <div className={styles.container}>
		<Typography variant="h1">We've received your order.</Typography>
		<Typography>Thank you for choosing us! We are already working on your request.</Typography>
		<img alt="thanks" src="https://images.unsplash.com/photo-1554830072-52d78d0d4c18?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80" />
	</div>;
}

export default ThanksPage