import { ProgressButton } from '@cassini-app/react';
import { Button, Fade, useTheme } from '@material-ui/core';
import React from 'react';
import { FlowOptions } from './types';

interface SlideContainerProps {
	options: FlowOptions;
	step: number;
	onBack?: () => void;
	onClose?: () => void;
	done?: boolean;
}

export const SlideContainer: React.FunctionComponent<SlideContainerProps> = ({ options, children, step, onBack, onClose, done }) => {
	const theme = useTheme();
	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (ref.current) {
			ref.current.scrollTo(0, 0);
		}
	}, [step]);

	return <div ref={ref} style={{ width: "100%", maxWidth: 500, padding: 10, minHeight: 300, overflowY: 'auto', backgroundColor: theme.palette.background.paper, borderRadius: 4 }}>
		<div style={{ display: 'flex', backgroundColor: theme.palette.background.default, margin: -10, minHeight: 50, position: 'sticky', width: 'calc(100% + 20px)', top: -10, zIndex: 2, borderBottom: '1px solid gray' }}>
			<div style={{ flex: 1, margin: '5px 10px' }}>
				<Fade in={step > 0}>
					<Button variant="text" onClick={onBack} style={{ position: 'absolute' }} className="editableNoOffset">{options.getText('backButton')}</Button>
				</Fade>
			</div>
			{onClose && <div style={{ flex: 1, margin: '5px 10px', textAlign: 'right' }}>
				{done !== true && <ProgressButton variant="text" color="primary" onClick={onClose} className="editableNoOffset">{options.getText('nevermindButton')}</ProgressButton>}
			</div>}
		</div>
		<div style={{ minHeight: 300, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
			{children}
		</div>
	</div >;
}