import React from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { GoogleAnalyticsEnabled } from './Config';

function pageview(path: string) {
	if (GoogleAnalyticsEnabled) {
		ReactGA.pageview(path);
	}
}

function initAnalytics() {
	if (GoogleAnalyticsEnabled) {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID!, { gaOptions: { cookieDomain: 'auto' } })
	}
}

let currentPage = "";
export const Analytics: React.FunctionComponent<{}> = () => {
	const location = useLocation();
	const path = location.pathname + location.search;
	if (currentPage !== path) {
		pageview(path);
		currentPage = path;
	}
	return <></>;
};

function anlayticsEvent(category: string, action: string, label: string | null | undefined) {
	if (GoogleAnalyticsEnabled) {
		ReactGA.event({ category, action, label: label || undefined });
	}
}

initAnalytics();

export {
	pageview,
	initAnalytics,
	anlayticsEvent
}