import { Dialog, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { CancelWidget } from './CancelWidget';
import { createTheme } from './styling';
import { Flow, FlowResult } from './types';

interface CancelDialogProps {
	flow: Flow;
	open: boolean;
	onClose: (result: FlowResult) => void;
	mode?: 'test' | 'demo';
	userPreferredColorMode?: "dark" | "light";
}

export const CancelDialog: React.FunctionComponent<CancelDialogProps> = ({ flow, open, onClose, mode, userPreferredColorMode }) => {
	return <ThemeProvider theme={createTheme(flow.options.style, userPreferredColorMode)}>
		<Dialog open={open}>
			<CancelWidget flow={flow} onNevermind={() => onClose({ status: 'incomplete' })} onClose={onClose} mode={mode} />
		</Dialog>
	</ThemeProvider>;
}