import { addTranslations, CassiniTanslationKeys, useUntypedTranslation } from '@cassini-app/react';
import appEnglishTranslations from './en/translations.json';

export type TanslationKeys = keyof typeof appEnglishTranslations;

addTranslations('en', appEnglishTranslations);

export function useTranslations() {
	const { t } = useUntypedTranslation();
	return (key: CassiniTanslationKeys | TanslationKeys) => {
		return t(key) ?? "";
	}
}