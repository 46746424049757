import Axios from "axios";
import { Reason } from "CancelWidget/types";
import { useQuery, useQueryClient } from "react-query";

export function useSaveReasons() {
	const queryClient = useQueryClient();
	return async (reasons: Reason[]) => {
		await Axios.post(`v1/reasons`, { reasons });
		queryClient.setQueryData('reasons', reasons);
	}
}

export async function getReasons() {
	const response = await Axios.get<{ reasons: Reason[] }>(`v1/reasons`);
	return response.data.reasons;
}

export function useReasons() {
	return useQuery('reasons', getReasons);
}