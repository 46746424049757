import Axios from "axios";
import { DefaultPhrases } from "CancelWidget/Defaults";
import { FlowOptions, FlowOptionsState, FlowStyle, Phrase } from "CancelWidget/types";
import produce from "immer";
import { useQuery, useQueryClient } from "react-query";

export function addGetText(options: FlowOptionsState): FlowOptions {
	return {
		...options,
		getText: (key) => {
			return options.phrases.find(p => p.key === key)?.text;
		}
	};
}

export function useSavePhrase() {
	const queryClient = useQueryClient();
	return async (phrase: Phrase) => {
		await Axios.post(`flow/options/phrase/${phrase.key}`, { phrase });
		const options = queryClient.getQueryData<FlowOptionsState>('options');
		if (options) {
			const updatedOptions = produce(options, opts => {
				const index = opts.phrases.findIndex(p => p.key === phrase.key);
				if (index >= 0) {
					opts.phrases[index] = phrase;
				}
				else {
					opts.phrases.push(phrase);
				}
			})
			queryClient.setQueryData<FlowOptions>('options', addGetText(updatedOptions));
		}
	}
}

export function mergeDefaultPhrases(phrases: Phrase[]) {
	for (const phrase of DefaultPhrases) {
		const existingPhrase = phrases.find(p => p.key === phrase.key);
		if (existingPhrase && (existingPhrase.text?.trim() ?? "").length === 0) {
			existingPhrase.text = phrase.text;
		}
		else if (existingPhrase === undefined) {
			phrases.push(phrase);
		}
	}
}

export async function getFlowOptions(): Promise<FlowOptions> {
	const response = await Axios.get<{ options: FlowOptionsState }>(`flow/options`);
	mergeDefaultPhrases(response.data.options.phrases);
	return addGetText(response.data.options);
}

export function useSaveStyling() {
	const queryClient = useQueryClient();
	return async (styling: FlowStyle) => {
		await Axios.post(`flow/options/styling`, { styling });
		const options = queryClient.getQueryData<FlowOptionsState>('options');
		if (options) {
			const updatedOptions = produce(options, opts => {
				opts.style = styling;
			})
			queryClient.setQueryData('options', updatedOptions);
		}
	}
}

export function useOptions() {
	return useQuery('options', getFlowOptions);
}