import { useTheme } from '@material-ui/core';
import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import jsSyntax from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import { atelierCaveDark as darkCodeStyle, atelierCaveLight as lightCodeStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

SyntaxHighlighter.registerLanguage('javascript', jsSyntax);

interface CodeProps {
	children: string;
}

export const Code: React.FunctionComponent<CodeProps> = ({ children }) => {
	const theme = useTheme();
	return <div style={{ fontSize: 14 }}>
		<SyntaxHighlighter showLineNumbers={true} language="javascript" style={theme.palette.type === "dark" ? darkCodeStyle : lightCodeStyle}>
			{children}
		</SyntaxHighlighter>
	</div>;
}