import { FormSelect } from '@cassini-app/react';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getCoupons } from 'Api/Plans';
import React from 'react';
import { useQuery } from 'react-query';

interface SelectCouponProps {
	name: string;
}

export const SelectCoupon: React.FunctionComponent<SelectCouponProps> = ({ name }) => {
	const couponsQuery = useQuery('coupons', getCoupons);
	if (couponsQuery.isFetching) {
		return <Skeleton style={{ height: 50, transform: "scale(1, 0.9)" }} />
	}
	else if (couponsQuery.data === undefined) {
		return <Typography color="error">Unable to load coupons.</Typography>
	}
	if (couponsQuery.data.length === 0) {
		return <Typography color="error">Create a coupon in Stripe to select it here.</Typography>
	}
	return <FormSelect name={name} label="Coupon Id" options={couponsQuery.data.map(c => ({ label: c.name, value: c.id }))} />;
}