import { Typography, useTheme } from "@material-ui/core";
import { Point, SliceTooltipProps } from "@nivo/line";
import { ColorSquare } from "Dashboard/Charts/ColorSquare";
import React from "react";
import { formatValue } from "Utils/Formatters";

function sortPoints(a: Point, b: Point) {
	const aValue = a.data.y.valueOf()
	const bValue = b.data.y.valueOf()
	if (typeof aValue === 'number' && typeof bValue === 'number') {
		return bValue - aValue;
	}
	return -1;
}

export function SliceTooltip({ slice }: SliceTooltipProps) {
	const theme = useTheme();
	const style: React.CSSProperties = { padding: '3px 5px' }
	return <div style={{ backgroundColor: theme.palette.background.default, padding: 4 }}>
		<Typography style={{ textAlign: 'center' }}>{slice.points[0].data.xFormatted}</Typography>
		{slice.points.sort(sortPoints).map(point => {
			return <div key={point.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<ColorSquare color={point.serieColor} style={style} />
				<Typography style={{ ...style, flex: 1, maxWidth: "32vw", textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{point.serieId}</Typography>
				<Typography style={{ ...style, fontWeight: 'bold' }}>{formatValue(point.data.y)}</Typography>
			</div>
		})}
	</div>
}