import { Button, Divider, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import React from 'react';
import { useEditTextCallback } from 'Utils/useEditTextCallback';
import { MarkdownViewer } from './MarkdownViewer';
import { FlowOptions, PhraseKey, Reason } from './types';

interface ReasonSelectorProps {
	onSelected: (reason: { id: string, text: string }) => void;
	reasons: Reason[];
	options: FlowOptions;
	defaultReason?: string | null;
	defaultReasonText?: string;
	onEditText?: (keys: PhraseKey[]) => void;
}

export const ReasonSelector: React.FunctionComponent<ReasonSelectorProps> = ({ onSelected, reasons, options, defaultReason, defaultReasonText, onEditText }) => {
	const [id, setId] = React.useState<string | null>(defaultReason ?? null);
	const [text, setText] = React.useState(defaultReasonText ?? '');
	const handleChange = (e: any) => {
		setId(e.target.value);
	}
	const editText = useEditTextCallback(onEditText)
	return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 10 }}>
		<MarkdownViewer className={"editable"} onClick={editText(['reasonMessage'])}>{options.getText('reasonMessage')}</MarkdownViewer>
		<RadioGroup aria-label="coupons" name="coupons" value={id} onChange={handleChange}>
			{reasons.sort((a, b) => a.order - b.order).map(reason =>
				<FormControlLabel key={reason.id} value={reason.id} control={<Radio color="primary" />} label={reason.name} />
			)}
		</RadioGroup>
		<TextField
			multiline
			rows={3}
			placeholder={options.getText('moreInfoPlaceholder')}
			name="freeform"
			fullWidth
			value={text}
			onChange={e => setText(e.target.value)}
			onClick={editText(['moreInfoPlaceholder'])}
			style={{ cursor: onEditText ? 'pointer' : undefined }}
			InputProps={{ inputProps: { style: { cursor: onEditText ? 'pointer' : undefined } }, style: { cursor: onEditText ? 'pointer' : undefined } }}
			disabled={!!onEditText}
			className={"editable"}
		/>
		<Divider style={{ margin: '10px 0' }} />
		<MarkdownViewer className={"editable"} onClick={editText(['cancelConfirmationDetails'])}>{options.getText('cancelConfirmationDetails')}</MarkdownViewer>
		<Button type="submit" disabled={id === null && onEditText === undefined} color="secondary"
			onClick={editText(['cancelConfirmationTitle']) ?? (() => onSelected({ id: id ?? "", text }))}
			style={{ marginTop: 10 }} className={"editable"}>{options.getText('cancelConfirmationTitle')}</Button>
	</div>;
}