import { useOffers } from 'Api/Offers';
import { useReasons } from 'Api/Reasons';
import { useSummaryReport } from 'Api/Reports';
import React from 'react';
import { CHART_COLORS, NOT_SELECTED, NOT_SELECTED_COLOR } from 'Utils/Constants';
import { InteractionHistory } from './InteractionHistory';

interface HistoryPageProps {
	startDate: Date;
	endDate: Date;
}

export const HistoryPage: React.FunctionComponent<HistoryPageProps> = ({ startDate, endDate }) => {
	const offersQuery = useOffers();
	const reasonsQuery = useReasons();

	const summary = useSummaryReport(startDate, endDate);
	const reasonColorPicker = React.useMemo(() => (value: string) => {
		const reasonKeys = [NOT_SELECTED].concat(reasonsQuery.data?.map(r => r.name) ?? []);
		const reasonColors = [NOT_SELECTED_COLOR].concat(CHART_COLORS);
		return reasonColors[reasonKeys.findIndex(r => r === value)]
	}, [reasonsQuery.data]);
	const interactions = summary.data?.interactions;
	return <InteractionHistory reasons={reasonsQuery.data} offers={offersQuery.data} interactions={interactions} colorPicker={reasonColorPicker} />;
}