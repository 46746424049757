import { Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getPlans } from 'Api/Plans';
import React from 'react';
import { useQuery } from 'react-query';
import { FormChipList } from './FormChipList';

interface PlanChipListProps {
	name: string;
}

export const PlanChipList: React.FunctionComponent<PlanChipListProps> = ({ name }) => {
	const plansQuery = useQuery('plans', getPlans);
	return <>
		{plansQuery.data && <FormChipList name={name} options={plansQuery.data.map(p => ({ label: p.name, value: p.id, defaultChecked: false }))} addLabel={"Plans"} />}
		{plansQuery.isLoading && <Skeleton style={{ height: 50, transform: "scale(1, 0.9)" }} />}
		{plansQuery.isError && <div style={{ display: 'flex', alignItems: 'center' }}>
			<Chip color="secondary" label="Unable to load plans." />
		</div>}
	</>;
}