import { Form, ProgressButton } from '@cassini-app/react';
import { Button, Checkbox, Container, Divider, FormControlLabel, Popover, ThemeProvider, Typography, useTheme } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { useOffers } from 'Api/Offers';
import { useReasons } from 'Api/Reasons';
import { useSaveStyling } from 'Api/Settings';
import { ConfirmationSlide } from 'CancelWidget/ConfirmationSlide';
import { InlineSlideHolder } from 'CancelWidget/InlineSlideHolder';
import { OptionsSlide } from 'CancelWidget/OptionsSlide';
import { ReasonSelector } from 'CancelWidget/ReasonSelector';
import { RecoverSlide } from 'CancelWidget/RecoverSlide';
import { SlideContainer } from 'CancelWidget/SlideContainer';
import { createTheme } from 'CancelWidget/styling';
import { ColorMode, DisplayTime, FlowOptions, Offer, OfferType, Phrase, PhraseKey } from 'CancelWidget/types';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { ChromePicker } from 'react-color';
import { v4 as uuid } from 'uuid';
import { ReactComponent as CheckmarkIcon } from '../Icons/checkmark.svg';
import { ReactComponent as GearIcon } from '../Icons/gear.svg';
import { ReactComponent as ListIcon } from '../Icons/list.svg';
import { ReactComponent as MoonIcon } from '../Icons/moon.svg';
import { ReactComponent as PriceTagIcon } from '../Icons/pricetag.svg';
import { ReactComponent as RightArrowIcon } from '../Icons/rightarrow.svg';
import { ReactComponent as SunIcon } from '../Icons/sun.svg';
import { FieldDefinition, UpdateTextDialog } from './UpdateTextDialog';

const fields: FieldDefinition[] = [
	{
		id: "backButton",
		type: 'plain',
		label: "Back Button"
	},
	{
		id: "welcomeMessage",
		type: "markdown",
		label: "Welcome"
	},
	{
		id: "recoverMessage",
		type: "markdown",
		label: "Special Offers"
	},
	{
		id: "reasonMessage",
		type: "markdown",
		label: "Select Reason"
	},
	{
		id: "moreInfoPlaceholder",
		type: "plain",
		label: "More Info Placeholder",
	},
	{
		id: "nevermindButton",
		type: "plain",
		label: "Exit Cancellation Process Button"
	},
	{
		id: "pauseConfirmation",
		type: "plain",
		label: "Pause Subscription Confirmation Button"
	},
	{
		id: "couponConfirmation",
		type: "plain",
		label: "Apply Coupon Confirmation Button"
	},
	{
		id: "extendTrialConfirmation",
		type: "plain",
		label: "Extend Trial Confirmation Button"
	},
	{
		id: "planConfirmation",
		type: "plain",
		label: "Change Plan Confirmation Button"
	},
	{
		id: "cancelConfirmationTitle",
		type: "plain",
		label: "Cancel Subscription Action"
	},
	{
		id: "cancelConfirmationDetails",
		type: "markdown",
		label: "Cancel Subscription Details"
	},
	{
		id: 'done',
		type: "plain",
		label: "Done Button"
	}
]

interface CustomizeBuilderProps {
	options: FlowOptions | undefined;
}

const beforeOfferPlaceholder: Offer = { name: "Offer 1", details: "Offers displayed before cancellation reason will be here.", id: uuid(), type: OfferType.Text, displayTime: DisplayTime.BeforeReason, confirmationMessage: "", conditions: [], enabled: true };
const afterOfferPlaceholder: Offer = { name: "Offer 2", details: "Offers displayed after cancellation reason will be here.", id: uuid(), type: OfferType.Text, displayTime: DisplayTime.AfterReason, confirmationMessage: "", conditions: [], enabled: true };

const confirmations: { id: PhraseKey, label: string }[] = [
	{
		id: 'pauseConfirmation',
		label: 'The confirmation message displayed here is set when creating an offer. The buttons below will be displayed based on the offer type.\n\nPause Subscription Offer:'
	},
	{
		id: 'couponConfirmation',
		label: 'Coupon Offer:'
	},
	{
		id: 'extendTrialConfirmation',
		label: 'Extend Trial Offer:'
	},
	{
		id: 'planConfirmation',
		label: 'Change Plan Offer:'
	},
	{
		id: 'done',
		label: 'Display Message Offer:'
	}
]

const pages = [
	{
		value: 0,
		label: "Options",
		icon: <GearIcon style={{ width: 30, height: 30 }} />
	},
	{
		value: 1,
		label: "Reasons",
		icon: <ListIcon style={{ width: 30, height: 30 }} />
	},
	{
		value: 2,
		label: "Recover",
		icon: <PriceTagIcon style={{ width: 30, height: 30 }} />
	},
	{
		value: 3,
		label: "Confirmation",
		icon: <CheckmarkIcon style={{ width: 30, height: 30 }} />
	}];

export const CustomizeBuilder: React.FunctionComponent<CustomizeBuilderProps> = ({ options }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const theme = useTheme();
	const reasons = useReasons();
	const offers = useOffers();
	const [page, setPage] = React.useState(0);
	const [pickedColor, setPickedColor] = React.useState(options?.style.color ?? "#3A4DC8");
	const [pickedColorMode, setPickedColorMode] = React.useState(options?.style.colorMode ?? ColorMode.Auto);
	const [editTextDialogOpen, setEditTextDialogOpen] = React.useState(false);
	const [templates, setTemplates] = React.useState<{ phrase: Phrase, field: FieldDefinition }[]>([])
	const [showBorders, setShowBorders] = React.useState(true);
	const saveStyling = useSaveStyling();

	React.useEffect(() => {
		if (options) {
			setPickedColor(options.style.color);
			setPickedColorMode(options.style.colorMode)
		}
	}, [options])

	const submitStyling = async (newOptions: FlowOptions) => {
		await saveStyling(newOptions.style);
	}

	if (options === undefined) {
		return <Container>
			<Skeleton style={{ height: 200, transform: "scale(1)", margin: theme.spacing(2) }} />
			<div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
				<Skeleton style={{ height: 500, transform: "scale(1)", width: "100%", maxWidth: 500, padding: 10, margin: theme.spacing(2) }} />
				<Skeleton style={{ height: 250, transform: "scale(1)", flex: 1, margin: theme.spacing(2) }} />
			</div>
		</Container>
	}

	const editText = (keys: PhraseKey[]) => {
		const templates = fields.filter(field => keys.includes(field.id)).map(field => {
			const phrase = options.phrases.find(o => o.key === field.id) ?? { key: field.id, text: "" };
			return {
				phrase,
				field
			}
		});
		setTemplates(templates);
		setEditTextDialogOpen(true);
	}

	return <Container>
		<Alert color="info" className="editMode">
			<Typography>Customize the look and feel of the cancellation popup your customers will receive. You can click on <span className="editable" style={{ margin: "0 8px" }}>any text with a border</span> to change it.</Typography>
		</Alert>

		<UpdateTextDialog open={editTextDialogOpen} onClose={() => setEditTextDialogOpen(false)} templates={templates} />

		<Typography style={{ margin: theme.spacing(2) }}>Pages</Typography>
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', maxWidth: 800, flexWrap: "wrap" }}>
			{pages.map((p, i) => {
				return <React.Fragment key={p.value}>
					<Button
						key={p.value}
						variant={p.value === page ? "contained" : "outlined"}
						color={p.value === page ? "primary" : "default"}
						style={{ fill: p.value === page ? "white" : theme.palette.text.primary, margin: theme.spacing(1) }}
						onClick={() => setPage(p.value)}
					>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
							{p.icon}
							{p.label}
						</div>
					</Button>
					{i < pages.length - 1 && <RightArrowIcon style={{ fill: theme.palette.text.primary, stroke: theme.palette.text.primary, width: 30, height: 30 }} />}
				</React.Fragment>;
			})}
		</div>
		<Divider style={{ margin: theme.spacing(2) }} />
		<div style={{ display: 'flex', flexWrap: 'wrap' }} className={showBorders ? "editMode" : "notEditMode"}>
			<div>
				{(page === 2 || page === 0) && <Alert color="info" style={{ width: '100%', maxWidth: 530 }}>
					<Typography>This page will only be displayed if there are offers the customer can select.</Typography>
				</Alert>}
				<ThemeProvider theme={createTheme({ color: pickedColor, colorMode: pickedColorMode === ColorMode.Auto ? (theme.palette.type === 'light' ? ColorMode.Light : ColorMode.Dark) : pickedColorMode })}>
					<InlineSlideHolder>
						<SlideContainer step={page} options={options} onClose={() => editText(["nevermindButton"])} onBack={() => editText(["backButton"])}>
							{page === 0 && <OptionsSlide offers={offers.data?.filter(o => o.enabled && o.displayTime === DisplayTime.BeforeReason) ?? [beforeOfferPlaceholder]} options={options} onEditText={editText} />}
							{page === 1 && <ReasonSelector onSelected={() => { }} reasons={reasons.data ?? []} options={options} onEditText={editText} />}
							{page === 2 && <RecoverSlide offers={offers.data?.filter(o => o.enabled && o.displayTime === DisplayTime.AfterReason) ?? [afterOfferPlaceholder]} options={options} onEditText={editText} />}
							{page === 3 && <>
								{confirmations.map(c => <ConfirmationSlide key={c.id} confirmationButtonText={options.getText(c.id)} confirmationMessage={c.label} onButtonClick={() => editText([c.id])} />)}
							</>}
						</SlideContainer>
					</InlineSlideHolder>
				</ThemeProvider>
			</div>
			<Form initialValues={options} onSubmit={submitStyling} enableReinitialize style={{ margin: '40px 0' }}>
				<div>
					<FormControlLabel style={{ margin: theme.spacing(1) }} control={<Checkbox color="primary" checked={showBorders} onChange={() => setShowBorders(!showBorders)} />} label="Display Editable Text Borders" />
					<Field name="style.color">
						{({ field, form }: FieldProps) => <div style={{ margin: theme.spacing(2) }}>
							<Typography>Theme Color</Typography>
							<div style={{ margin: theme.spacing(1), width: 36, height: 36, backgroundColor: field.value, border: 'solid 1px gray', borderRadius: 4, cursor: 'pointer' }} onClick={e => setAnchorEl(e.currentTarget)}></div>
							<Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={e => setAnchorEl(null)}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
								transformOrigin={{ vertical: 'top', horizontal: 'center', }}
							>
								<ChromePicker disableAlpha={true} color={field.value} onChange={c => { form.setFieldValue('style.color', c.hex); setPickedColor(c.hex) }} />
							</Popover>
						</div>}
					</Field>

					<Field name="style.colorMode">
						{({ field, form }: FieldProps) => {
							const update = (mode: ColorMode) => {
								form.setFieldValue('style.colorMode', mode);
								setPickedColorMode(mode);
							}
							return <div style={{ margin: theme.spacing(2) }}>
								<Typography>Color Mode</Typography>
								{ColorModes.map(mode => <Button key={mode.mode}
									style={{ margin: theme.spacing(1) }}
									variant={mode.mode === field.value ? "contained" : "outlined"}
									color={mode.mode === field.value ? "primary" : "default"}
									onClick={() => update(mode.mode)}>{mode.label}</Button>)}
							</div>
						}}
					</Field>
				</div>

				<ProgressButton type="submit" color="primary">Save</ProgressButton>
			</Form>
		</div>
	</Container>;
}

const ColorModes = [
	{
		mode: ColorMode.Auto,
		label: "Auto Detect",
		icon: <MoonIcon />
	},
	{
		mode: ColorMode.Light,
		label: "Light Mode",
		icon: <MoonIcon />
	},
	{
		mode: ColorMode.Dark,
		label: "Dark Mode",
		icon: <SunIcon />
	}
]