import { Typography } from '@material-ui/core';
import React from 'react';
import { ColorSquare } from './ColorSquare';

interface LegendItemProps {
	color: string;
	value: string;
}

export const LegendItem: React.FunctionComponent<LegendItemProps> = ({ color, value }) => {
	return <div style={{ display: "flex", alignItems: 'center', margin: '0px 8px' }}>
		<ColorSquare color={color} />
		<Typography>{value}</Typography>
	</div>;
}