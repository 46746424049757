import { Link, PagedQueryOptions, PagedTable, ProgressButton, redirectToCustomerPage, useAllWorkspaces } from '@cassini-app/react';
import { Container } from '@material-ui/core';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { WorkspaceActions } from './WorkspaceActions';

const WorkspacesPage: React.FunctionComponent<{}> = () => {
	const [queryOptions, setQueryOptions] = React.useState<PagedQueryOptions | undefined>(undefined);
	const workspaces = useAllWorkspaces(queryOptions);

	const columns: MUIDataTableColumnDef[] = [
		{
			name: "workspace", label: "Name", options: {
				customBodyRenderLite: (dataIndex) => {
					const workspace = workspaces?.data?.items[dataIndex];
					return <Link to={`/workspaces/${workspace?.id}/users`}>{workspace?.name}</Link>
				}
			}
		},
		{
			name: "actions", label: "Actions", options: {
				customBodyRenderLite: (dataIndex) => {
					const workspace = workspaces?.data?.items[dataIndex]!;
					return <WorkspaceActions workspace={workspace} />
				}
			}
		},
		{
			name: "stripe", label: "Stripe", options: {
				customBodyRenderLite: (dataIndex) => {
					const workspace = workspaces?.data?.items[dataIndex];
					if (workspace?.id) {
						return <ProgressButton variant="contained" color="primary" onClick={() => redirectToCustomerPage(workspace.id)}>Account</ProgressButton>
					}
					return <></>
				}
			}
		}
	]

	return <Container>
		<PagedTable data={workspaces} columns={columns} title="Organizations" queryOptions={queryOptions} onQueryOptionsChange={setQueryOptions} />
	</Container>;
}

export default WorkspacesPage