import '@brainhubeu/react-carousel/lib/style.css';
import { saveInteraction } from 'Api/Flow';
import produce from 'immer';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { ConfirmationSlide } from './ConfirmationSlide';
import { OptionsSlide } from './OptionsSlide';
import { ReasonSelector } from './ReasonSelector';
import { RecoverSlide } from './RecoverSlide';
import { SlideContainer } from './SlideContainer';
import { ConditionOperator, ConditionType, DisplayTime, Flow, FlowInteraction, FlowInteractionStatus, FlowOptions, FlowResult, Offer, OfferType } from './types';

interface CancelWidgetProps {
	flow: Flow;
	onNevermind?: () => void;
	onClose: (result: FlowResult) => void;
	mode?: 'test' | 'demo';
	hideCancel?: boolean;
}

type Step = 'options' | 'reasons' | 'recover' | 'confirmation';

function getConfirmationText(offer: Offer | undefined, options: FlowOptions) {
	switch (offer?.type) {
		case OfferType.Coupon:
			return options.getText('couponConfirmation');
		case OfferType.ExtendTrial:
			return options.getText('extendTrialConfirmation');
		case OfferType.Pause:
			return options.getText('pauseConfirmation');
		case OfferType.Plan:
			return options.getText('planConfirmation');
		case OfferType.Text:
			return options.getText('done');
		default:
			return undefined;
	}
}

interface CancelWidgetState extends FlowInteraction {
	step: number;
}

function getConfirmationOffers(offers: Offer[], selectedReason: string | undefined) {
	return offers.filter(offer => {
		const reasonConditions = offer.conditions?.filter(c => c.type === ConditionType.Reason);
		return offer.displayTime === DisplayTime.AfterReason &&
			(selectedReason === undefined || reasonConditions === undefined || reasonConditions.every(condition => {
				if (condition.operator === ConditionOperator.Require) {
					return condition.ids?.some(reasonId => reasonId === selectedReason)
				}
				else {
					return condition.ids?.every(reasonId => reasonId !== selectedReason);
				}
			}));
	});
}

export const CancelWidget: React.FunctionComponent<CancelWidgetProps> = ({ flow, onNevermind, onClose, mode, hideCancel }) => {
	const { reasons, options, offers } = flow;
	const welcomeOffers = offers.filter(o => o.displayTime === DisplayTime.BeforeReason);
	const [flowStatus, setFlowStatus] = React.useState<CancelWidgetState>({ status: FlowInteractionStatus.Incomplete, id: `flowinteraction.${uuid()}`, step: 0, customer: flow.customer ?? "", testMode: mode !== undefined });

	const confirmationId = flowStatus.offer?.id;

	const confirmationOffers = getConfirmationOffers(offers, flowStatus.reason?.id);

	const onOfferSelected = (action: Offer) => {
		setFlowStatus(produce(flowStatus, f => {
			f.offer = {
				id: action.id,
				type: action.type,
			};
			if (f.step === 0) {
				f.reason = undefined;
			}
			f.step += 1;
		}))
	}

	const onCancelSubscription = () => {
		setFlowStatus(produce(flowStatus, f => {
			f.step += 1;
			f.offer = undefined;
		}))
	}

	const steps: Step[] = [];
	if (welcomeOffers.length > 0) {
		steps.push('options');
	}
	if (flowStatus.offer === undefined || steps.length === 0 || flowStatus.reason !== undefined) {
		steps.push('reasons')
		if (confirmationOffers.length > 0) {
			steps.push('recover');
		}
	}
	steps.push('confirmation');

	const isDone = flowStatus.step === steps.length - 1 && (flowStatus.offer === undefined || flowStatus.offer.type === OfferType.Text);

	const offerToConfirm = offers.find(o => o.id === confirmationId);

	const onComplete = async (close: boolean) => {
		const finalStatus = produce(flowStatus, f => {
			f.status = FlowInteractionStatus.Saved;
		});

		setFlowStatus(finalStatus);
		if (mode !== "demo") {
			await saveInteraction(finalStatus);
		}
		if (close) {
			onClose({ status: 'saved' });
		}
	}

	const onReasonSelected = (reason: { id: string, text: string }) => {
		setFlowStatus(produce(flowStatus, f => {
			f.reason = reason;
			f.step += 1;
		}));
		const confirmationOffers = getConfirmationOffers(offers, reason.id);
		if (confirmationOffers.length < 1) {
			onComplete(true);
		}
	}

	const onBack = () => {
		setFlowStatus(produce(flowStatus, f => {
			f.step -= 1;
		}))
	}

	const onCompleteCancel = async () => {
		const finalStatus = produce(flowStatus, f => {
			f.offer = undefined;
			f.status = FlowInteractionStatus.Cancelled;
		});
		setFlowStatus(finalStatus);
		if (mode !== "demo") {
			await saveInteraction(finalStatus);
		}
		onClose({ status: 'cancelled' });
	}

	const onNevermindClick = onNevermind ? async () => {
		if ((flowStatus.reason !== undefined || flowStatus.offer !== undefined) && mode !== "demo") {
			const finalStatus = produce(flowStatus, f => {
				f.status = FlowInteractionStatus.Incomplete;
			});
			await saveInteraction(finalStatus);
		}
		onNevermind();
	} : undefined;

	const confirmationButtonText = getConfirmationText(offerToConfirm, options);

	const slides: JSX.Element[] = [];
	if (steps.includes('options')) {
		slides.push(<OptionsSlide offers={welcomeOffers} options={options} onOfferSelected={onOfferSelected} onCancel={onCancelSubscription} key={slides.length} hideCancel={hideCancel} />);
	}
	if (steps.includes('reasons')) {
		slides.push(<div key={slides.length}>
			<ReasonSelector onSelected={onReasonSelected} reasons={reasons} options={options} defaultReason={flowStatus.reason?.id} defaultReasonText={flowStatus.reason?.text} />
		</div>);
	}
	if (steps.includes('recover')) {
		slides.push(<RecoverSlide key={slides.length} offers={confirmationOffers} options={options} onCancel={onCompleteCancel} onOfferSelected={onOfferSelected} />)
	}
	slides.push(<ConfirmationSlide key={slides.length} onLinkClicked={() => onComplete(false)} onButtonClick={() => onComplete(true)} confirmationMessage={offerToConfirm?.confirmationMessage}
		confirmationButtonText={confirmationId !== 'cancel' ? confirmationButtonText : undefined} />)
	return <SlideContainer done={isDone} step={flowStatus.step} options={options} onBack={onBack} onClose={onNevermindClick}>
		{slides[flowStatus.step]}
	</SlideContainer>
}