import { Form, FormErrorMessage, FormIf, FormSelect, FormTextField, ProgressButton } from '@cassini-app/react';
import { Container, Divider, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSaveOffer } from 'Api/Offers';
import { useOptions } from 'Api/Settings';
import { CancelWidget } from 'CancelWidget/CancelWidget';
import { InlineSlideHolder } from 'CancelWidget/InlineSlideHolder';
import { ConditionOperator, ConditionType, DisplayTime, Offer, OfferType, OfferTypeNames, PlanSwitchBehavior, Reason } from 'CancelWidget/types';
import { InlineSelectTheme } from 'Components/InlineSelectTheme';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { ConditionBuilder } from './Conditions/ConditionBuilder';
import { SelectCoupon } from './SelectCoupon';
import { SelectPlan } from './SelectPlan';

interface OfferBuilderProps {
	offers: Offer[] | undefined;
	reasons: Reason[];
}

const schema = yup.object().shape({
	name: yup.string().required("A name is required.").max(200, "Name cannot be more than 200 characters."),
	details: yup.string().notRequired().max(1000, "Details cannot be more than 1000 characters."),
	confirmationMessage: yup.string().notRequired().max(1000, "Confirmation message cannot be more than 1000 characters."),
	type: yup.number(),
	attributes: yup.object()
		.when("type", {
			is: type => type === OfferType.Pause || type === OfferType.ExtendTrial,
			then: yup.object().shape({
				days: yup.number()
					.min(1, "Value must be at least 1 day.")
					.max(365, "Value can be at most 365 days.")
					.typeError("Value must be a number.")
					.required("Number of days is required.")
			}).typeError("Number of days is required.")
		})
		.when("type", {
			is: OfferType.Plan,
			then: yup.object().shape({
				planId: yup.string().matches(/[(plan)(price)]_.*/, 'Plan must start with "plan_" or "price_"').required("Plan Id is required."),
				planSwitchBehavior: yup.number().min(0).max(2).required("You must choose how and when the plan will be switched.")
			}).typeError("Plan Id and how the plan will be switched are required.")
		})
		.when("type", {
			is: OfferType.Coupon,
			then: yup.object().shape({
				couponId: yup.string().required("Copuon Id is required.")
			}).typeError("Copuon Id is required.")
		})
		.when("type", {
			is: OfferType.Text,
			then: yup.object().nullable()
		}),
	conditions: yup.array(yup.object({
		type: yup.number().min(0).max(Object.keys(ConditionType).length / 2).required(),
		operator: yup.number().min(0).max(Object.keys(ConditionOperator).length / 2).required(),
		id: yup.string().required(),
		ids: yup.array(yup.string()).min(1)
			.when("type", {
				is: ConditionType.Offer,
				then: yup.array(yup.string()).min(1, "You must add at least one offer.").required("You must add at least one offer.")
			}).when("type", {
				is: ConditionType.Plan,
				then: yup.array(yup.string()).min(1, "You must add at least one plan.").required("You must add at least one plan.")
			}).when("type", {
				is: ConditionType.Reason,
				then: yup.array(yup.string()).min(1, "You must add at least one reason for cancellation.").required("You must add at least one reason for cancellation.")
			})
	})).nullable()
})

function createOffer(): Offer {
	return {
		type: OfferType.Pause,
		name: "",
		id: `offer.${uuidv4()}`,
		displayTime: DisplayTime.AfterReason,
		details: "",
		enabled: true,
		confirmationMessage: "",
		conditions: []
	}
}

const offerTypes = [
	{ label: OfferTypeNames[OfferType.Pause], value: OfferType.Pause },
	{ label: OfferTypeNames[OfferType.Plan], value: OfferType.Plan },
	{ label: OfferTypeNames[OfferType.ExtendTrial], value: OfferType.ExtendTrial },
	{ label: OfferTypeNames[OfferType.Coupon], value: OfferType.Coupon },
	{ label: OfferTypeNames[OfferType.Text], value: OfferType.Text },
]

const planSwitchOptions = [
	{ value: PlanSwitchBehavior.AtPeriodEnd, label: "at the end of the current billing period." },
	{ value: PlanSwitchBehavior.ImmediatelyProrate, label: "immediately and prorate." },
	{ value: PlanSwitchBehavior.ImmediatelyNoProrate, label: "immediately without prorating." }
]

export const OfferBuilder: React.FunctionComponent<OfferBuilderProps> = ({ offers, reasons }) => {
	const route = useRouteMatch<{ id: string }>();
	const offer = React.useMemo(() => offers?.find(o => o.id === route.params.id) ?? createOffer(), [offers, route.params.id]);
	const theme = useTheme();
	const history = useHistory();
	const options = useOptions();
	const saveOffer = useSaveOffer();

	const submit = async (newOffer: Offer) => {
		await saveOffer([newOffer]);
		if (route.params.id === "new") {
			history.push(`/build/offers/${offer.id}`)
		}

	}

	return <Container>
		<div style={{ marginBottom: 20 }}>
			<Form<Offer> initialValues={offer} validationSchema={schema} enableReinitialize={true} onSubmit={submit} validateOnChange={false} validateOnBlur={false} style={{ flexDirection: 'row', flexWrap: "wrap-reverse" }}>
				<div style={{ flex: '1 1 300px', maxWidth: '100%' }}>
					<FormTextField label="Title" name="name" />
					<FormErrorMessage name="name" />
					<FormTextField multiline label="Details (optional markdown supported)" name="details" />
					<FormErrorMessage name="details" />
					<FormTextField multiline label={<>Confirmation Message (markdown supported)</>} name="confirmationMessage" />
					<FormErrorMessage name="confirmationMessage" />

					<FormSelect name="type" label="Offer Type" options={offerTypes} />
					<FormIf name="type" value={OfferType.Pause}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Typography style={{ display: 'flex', alignItems: 'center' }}>Pause subscription for</Typography>
							<FormTextField variant="outlined" name="attributes.days" style={{ width: 50, margin: '10px 10px' }} InputProps={{ inputProps: { style: { textAlign: 'center', padding: 10 } } }} />
							<Typography>days</Typography>
						</div>
						<FormErrorMessage name="attributes.days" />
					</FormIf>
					<FormIf name="type" value={OfferType.ExtendTrial}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Typography style={{ display: 'flex', alignItems: 'center' }}>Extend trial for</Typography>
							<FormTextField variant="outlined" name="attributes.days" style={{ width: 50, margin: '10px 10px' }} InputProps={{ inputProps: { style: { padding: 10, textAlign: 'center' } } }} />
							<Typography>days</Typography>
						</div>
						<FormErrorMessage name="attributes.days" />
					</FormIf>
					<FormIf name="type" value={OfferType.Plan}>
						<SelectPlan name="attributes.planId" selectStyle={{ maxWidth: 'calc(100% - 8px)' }} />
						<FormErrorMessage name="attributes.planId" />
						<div style={{ display: 'flex', alignItems: 'baseline' }}>
							<Typography>Change plan</Typography>
							<InlineSelectTheme>
								<FormSelect name="attributes.planSwitchBehavior" options={planSwitchOptions} />
							</InlineSelectTheme>
						</div>
						<FormErrorMessage name="attributes.planSwitchBehavior" />
					</FormIf>
					<FormIf name="type" value={OfferType.Coupon}>
						<SelectCoupon name="attributes.couponId" />
						<FormErrorMessage name="attributes.couponId" />
					</FormIf>
					<FormErrorMessage name="attributes" />
					<div style={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap' }}>
						<Typography>Display</Typography>
						<InlineSelectTheme>
							<FormSelect name="displayTime" variant="outlined" style={{ margin: 10 }} options={[{ label: "before", value: DisplayTime.BeforeReason }, { label: "after", value: DisplayTime.AfterReason }]} />
						</InlineSelectTheme>
						<Typography>cancellation reason is provided</Typography>
					</div>

					<Divider style={{ margin: '20px 0' }} />
					<Typography color="textPrimary">Conditions</Typography>
					<Typography color="textSecondary">Only provide this offer to customers that meet the following conditions.</Typography>
					{offers && <InlineSelectTheme><ConditionBuilder reasons={reasons} offers={offers} /></InlineSelectTheme>}
					{offers === undefined && <Skeleton style={{ height: 150 }} />}
					<ProgressButton type="submit" color="primary" style={{ marginTop: 10 }} fullWidth>Save</ProgressButton>
				</div>
				<div style={{ flex: '1 1 300px' }}>
					<Typography style={{ margin: theme.spacing(2) }}>Preview</Typography>
					<Field name="details">
						{({ field: detailsField }: FieldProps) =>
							<Field name="name">
								{({ field: nameField }: FieldProps) => {
									return <Field name="confirmationMessage">
										{({ field: confirmationField }: FieldProps) => <>
											{options.data && <InlineSlideHolder style={{ alignSelf: 'flex-start', width: `calc(100% - ${theme.spacing(2) * 2}px)` }}>
												<CancelWidget
													flow={{
														offers: [
															{
																...offer,
																confirmationMessage: confirmationField.value,
																name: nameField.value,
																details: detailsField.value,
																displayTime: DisplayTime.BeforeReason
															}
														],
														options: options.data,
														reasons: []
													}}
													onClose={() => { }}
													hideCancel
													mode="demo"
												/>
											</InlineSlideHolder>}
										</>}
									</Field>
								}}
							</Field>}
					</Field>
				</div>

			</Form>
		</div>
	</Container>;
}