import { createMuiTheme } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { ColorMode } from "./types";

const darkPalette = (color: string | undefined): PaletteOptions => ({
	type: 'dark',
	primary: {
		main: color ?? "#3f51b5"
	},
	background: {
		default: '#191c20',
		paper: '#2C2C2C'
	},
	secondary: {
		main: '#c62828'
	},
});

const lightPalette = (color: string | undefined): PaletteOptions => ({
	type: 'light',
	primary: {
		main: color ?? "#3f51b5"
	},
	background: {
		default: '#f8fafc',
		paper: '#fcfcfc'
	},
	secondary: {
		main: '#c62828'
	},
});

export function createTheme({ colorMode, color }: { colorMode: ColorMode, color: string }, userPreferredColorMode?: "dark" | "light") {
	const autoColorMode = userPreferredColorMode ?? (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light");
	let paletteType = autoColorMode;
	if (colorMode === ColorMode.Dark) {
		paletteType = "dark";
	}
	else if (colorMode === ColorMode.Light) {
		paletteType = "light";
	}
	const palette = paletteType === 'dark' ? darkPalette(color) : lightPalette(color);
	const theme = createMuiTheme({
		palette,
		props: {
			MuiTextField: {
				variant: 'outlined'
			},
			MuiButton: {
				variant: "contained"
			}
		}
	});
	return theme;
}