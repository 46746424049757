import React from 'react';
import { Workspace, useDeleteWorkspace, DeleteDialog } from '@cassini-app/react';
import { IconButton } from '@material-ui/core';
import { TrashIcon } from '../Icons';

export const WorkspaceActions: React.FC<{ workspace: Workspace }> = ({ workspace }) => {
	const deleteWorkspace = useDeleteWorkspace();
	const [open, setOpen] = React.useState(false);
	return <>
		<IconButton onClick={() => setOpen(true)}>
			<TrashIcon />
		</IconButton>
		<DeleteDialog confirmText={workspace.name} open={open} onClose={() => setOpen(false)} onDelete={async () => { await deleteWorkspace(workspace.id) }} />
	</>;
}