import Axios from "axios";
import { Flow, FlowInteraction } from "CancelWidget/types";
import produce from "immer";
import { addGetText, mergeDefaultPhrases } from "./Settings";

export async function saveInteraction(interaction: FlowInteraction) {
	if (interaction.reason?.id === "") {
		interaction = produce(interaction, i => {
			i.reason = undefined;
		})
	}
	await Axios.post(`integrations/flow/interaction`, { interaction });
}

export const getFlow = async (key: string, subscriptionId: string, mode?: string): Promise<Flow> => {
	const response = await Axios.get<Flow>(`integrations/flow/${key}?sub=${subscriptionId}&mode=${mode}`, {
		withCredentials: false
	});
	mergeDefaultPhrases(response.data.options.phrases);
	return {
		offers: response.data.offers,
		reasons: response.data.reasons,
		options: addGetText(response.data.options),
		customer: response.data.customer
	}
}

export interface PreviewSettings {
	currentPlan: string;
	currentlyTrialing: boolean;
	previousOffers: string[];
	currentlyPaused: boolean;
}

export const previewFlow = async (settings: PreviewSettings): Promise<Flow> => {
	const response = await Axios.post<Flow>(`preview/flow/`, settings);
	mergeDefaultPhrases(response.data.options.phrases);
	return {
		offers: response.data.offers,
		reasons: response.data.reasons,
		options: addGetText(response.data.options),
		customer: response.data.customer
	}
}