export interface Reason {
	name: string;
	order: number;
	id: string;
	archived: boolean;
}

export enum DisplayTime {
	BeforeReason,
	AfterReason,
}

export enum ConditionType {
	Reason,
	Plan,
	Offer
}

export enum ConditionOperator {
	Require,
	Exclude
}

export interface Condition {
	type: ConditionType;
	operator: ConditionOperator;
	ids?: string[];
	id: string;
}

interface OfferDescription {
	id: string;
	name: string;
	details: string;
	displayTime: DisplayTime,
	enabled: boolean;
	confirmationMessage: string;
	conditions: Condition[] | null
}

export enum OfferType {
	Plan,
	Coupon,
	Text,
	Pause,
	ExtendTrial
}

export const OfferTypeNames = [
	"Change Plan",
	"Coupon",
	"Display Message",
	"Pause Subscription",
	"Extend Trial",
]

export interface PlanChangeOffer extends OfferDescription {
	attributes?: {
		planId: string;
	}
	type: OfferType.Plan;
}

export interface CouponOffer extends OfferDescription {
	attributes?: {
		couponId: string;
	}
	type: OfferType.Coupon;
}

export interface TextOffer extends OfferDescription {
	type: OfferType.Text;
}

export interface PauseOffer extends OfferDescription {
	attributes?: {
		length: number;
		pausePlanId: string;
	}
	type: OfferType.Pause;
}

export interface ExtendTrialOffer extends OfferDescription {
	attributes?: {
		length: number;
	}
	type: OfferType.ExtendTrial
}

export type Offer = PlanChangeOffer | CouponOffer | TextOffer | PauseOffer | ExtendTrialOffer;

export interface Option {
	name: string;
	details: string;
}

export enum ColorMode {
	Light,
	Dark,
	Auto
}

export type PhraseKey = 'welcomeMessage' | 'recoverMessage' | 'reasonMessage' | 'moreInfoPlaceholder' | 'nevermindButton' | 'pauseConfirmation' | 'couponConfirmation' |
	'extendTrialConfirmation' | 'planConfirmation' | 'cancelConfirmationTitle' | 'cancelConfirmationDetails' | 'backButton' | 'done';

export interface Phrase {
	key: PhraseKey;
	text: string;
	languageCode?: string;
}

export interface FlowStyle {
	color: string;
	colorMode: ColorMode;
}

export interface FlowOptionsState {
	phrases: Phrase[],
	style: FlowStyle
}

export interface FlowOptions extends FlowOptionsState {
	getText: (key: PhraseKey) => string | undefined;
}

export enum FlowInteractionStatus {
	Incomplete,
	Saved,
	Cancelled,
}

export interface FlowInteraction {
	status: FlowInteractionStatus,
	offer?: {
		type: OfferType;
		id: string;
	}
	reason?: {
		id: string;
		text: string;
	},
	id: string;
	customer: string;
	testMode?: boolean;
}

export interface Flow {
	offers: Offer[];
	reasons: Reason[];
	options: FlowOptions;
	customer?: string;
}

export interface CancelConfig {
	subscriptionId: string;
	mode?: 'test' | 'demo';
	apiKey: string;
	colorMode?: "light" | "dark";
	id: number;
}

export interface Activity {
	timestamp: string;
}

export interface Interaction extends Activity {
	id: string;
	timestamp: string;
	offerId: string | null;
	status: FlowInteractionStatus;
	offerType: OfferType | null;
	reasonId: string | null;
	reasonText: string;
	customerId: string;
	subscriptionid: string;
	customerName: string;
	customerStatus: CustomerStatus;
}

export enum CustomerStatus {
	Active,
	PendingSaved,
	Saved,
	Cancelled
}

export interface SavedRevenue extends Activity {
	id: string;
	workspaceId: string;
	customerId: string;
	timestamp: string;
	value: number;
}

export enum BillingProvider {
	Stripe
}

export interface ApiKey {
	id: string;
	provider: BillingProvider
}

export enum PlanSwitchBehavior {
	AtPeriodEnd,
	ImmediatelyProrate,
	ImmediatelyNoProrate
}

export interface FlowResult {
	status: 'incomplete' | 'saved' | 'cancelled';
}