import { useTheme } from '@material-ui/core';
import React from 'react';

interface InlineSlideHolderProps {
	style?: React.CSSProperties;
}

export const InlineSlideHolder: React.FunctionComponent<InlineSlideHolderProps> = ({ children, style }) => {
	const theme = useTheme();
	return <div style={{ maxWidth: 500, display: 'inline-block', boxShadow: `0 6px 20px 0px ${(theme.palette.type === 'light' ? 'grey' : 'black')}`, margin: theme.spacing(2), ...style }}>
		{children}
	</div>
}