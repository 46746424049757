import { Button, Card, CardContent, CardHeader, Divider, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FlowInteractionStatus, Interaction, Offer, Reason } from 'CancelWidget/types';
import { Dropdown } from 'Components/Dropdown';
import React from 'react';
import { NOT_SELECTED, NOT_SELECTED_COLOR } from 'Utils/Constants';
import { fullDateformatter } from 'Utils/Formatters';

interface InteractionHistoryProps {
	reasons?: Reason[];
	offers?: Offer[];
	interactions?: Interaction[];
	colorPicker: (reason: string) => string;
}

interface StatusDefition {
	[key: number]: {
		label: string;
		color: "error" | undefined;
		styleColor: string | undefined;
	}
}

const statuses: StatusDefition = {
	[FlowInteractionStatus.Cancelled]: { label: "Cancelled", color: "error", styleColor: undefined },
	[FlowInteractionStatus.Incomplete]: { label: "Incomplete", color: undefined, styleColor: "orange" },
	[FlowInteractionStatus.Saved]: { label: "Saved", color: undefined, styleColor: "green" }
}

export const InteractionHistory: React.FunctionComponent<InteractionHistoryProps> = ({ reasons, offers, interactions, colorPicker }) => {
	const [page, setPage] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const totalPages = Math.trunc((interactions?.length ?? 0) / perPage) + 1;
	const firstInteraction = 10 * page;
	let pageOptions: { label: string, value: number }[] = [];
	for (let i = 0; i < totalPages; ++i) {
		pageOptions.push({ label: `${i + 1}`, value: i });
	}
	React.useEffect(() => {
		if (page > totalPages) {
			setPage(totalPages - 1);
		}
	}, [page, perPage, totalPages])
	const loading = reasons === undefined || offers === undefined || interactions === undefined;
	return <Card style={{ margin: '10px 0px' }}>
		<CardHeader title="History" />
		{loading && <Skeleton variant="rect" style={{ width: '100%', height: '100%', minHeight: 200 }} />}
		{reasons && offers && interactions && <CardContent>
			{interactions.slice().reverse().slice(firstInteraction, Math.min(firstInteraction + perPage, interactions.length)).map(interaction => {
				const offer = offers.find(o => o.id === interaction.offerId);
				const reasonIndex = reasons.findIndex(r => r.id === interaction.reasonId);
				const status = statuses[interaction.status];
				return <div key={interaction.id}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
						<div style={{ flex: 1, flexBasis: 200 }}>
							<Link href={`https://dashboard.stripe.com/test/customers/${interaction.customerId}`} target="_blank"><Typography>{interaction.customerName}</Typography></Link>
							<Typography color="textSecondary">{fullDateformatter.format(new Date(interaction.timestamp))}</Typography>
						</div>
						<Typography color={status.color} style={{ flex: 1, flexBasis: 200, color: status.styleColor }}>{status.label}</Typography>
						<div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexBasis: 200 }}>
							{interaction.offerType !== null && <Typography>{offer?.name}</Typography>}
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexBasis: 200 }}>
							{reasonIndex >= 0 && <Typography style={{ color: colorPicker(reasons[reasonIndex].name) }}>{reasons[reasonIndex].name ?? ""}</Typography>}
							{reasonIndex < 0 && <Typography style={{ color: NOT_SELECTED_COLOR }}>{NOT_SELECTED}</Typography>}
						</div>
					</div>
					<Typography>{interaction.reasonText}</Typography>
					<Divider style={{ margin: "4px 0" }} />
				</div>
			})}
			{interactions.length === 0 && <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
				<Typography color="textSecondary">No data to display</Typography>
			</CardContent>}
			<div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					{totalPages > 1 && <>
						<Button color="primary" disabled={page === 0} onClick={() => setPage(page - 1)}>{"<"}</Button>
						<Dropdown style={{ margin: '0px 10px' }} variant="standard" value={page} options={pageOptions} onChange={(e: any) => setPage(e.target.value)} />
						<Button color="primary" disabled={page === totalPages - 1} onClick={() => setPage(page + 1)}>{">"}</Button>
					</>}
				</div>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<Typography>Display</Typography>
					<Dropdown variant="standard" value={perPage} options={[{ value: 10, label: "10" }, { value: 50, label: "50" }, { value: 100, label: "100" }]} onChange={(e: any) => setPerPage(e.target.value)} />
					<Typography>rows</Typography>
				</div>
			</div>
		</CardContent>}
	</Card>;
}