import { ProgressButton } from '@cassini-app/react';
import { Divider } from '@material-ui/core';
import React from 'react';
import { useEditTextCallback } from 'Utils/useEditTextCallback';
import { MarkdownViewer } from './MarkdownViewer';
import { OptionButton } from './OptionButton';
import { SlideStyle } from './Slide';
import { FlowOptions, Offer, PhraseKey } from './types';

interface RecoverSlideProps {
	offers: Offer[]
	options: FlowOptions;
	onOfferSelected?: (offer: Offer) => void;
	onCancel?: () => void;
	onEditText?: (keys: PhraseKey[]) => void;
}

export const RecoverSlide: React.FunctionComponent<RecoverSlideProps> = ({ offers, options, onOfferSelected, onCancel, onEditText }) => {
	const editText = useEditTextCallback(onEditText)
	return <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
		<div style={SlideStyle}>
			<MarkdownViewer className={"editable"} onClick={editText(['recoverMessage'])}>{options.getText('recoverMessage')}</MarkdownViewer>
		</div>
		<div style={{ overflow: 'auto' }}>
			{offers.map(offer => <OptionButton key={offer.id} option={offer} style={SlideStyle} onClick={onOfferSelected ? () => onOfferSelected(offer) : () => { }} />)}
		</div>
		<Divider style={{ margin: '10px 0' }} />
		<MarkdownViewer className={"editable"} onClick={editText(['cancelConfirmationDetails'])}>{options.getText('cancelConfirmationDetails')}</MarkdownViewer>
		<ProgressButton type="submit" color="secondary" onClick={editText(['cancelConfirmationTitle']) ?? onCancel} style={SlideStyle} className={"editable"}>
			{options.getText('cancelConfirmationTitle')}
		</ProgressButton>
	</div>;
}