import React from 'react';
import { MarkdownViewer } from './MarkdownViewer';
import { OptionButton } from './OptionButton';
import { SlideStyle } from './Slide';
import { FlowOptions, Offer, PhraseKey } from './types';

interface OptionsSlideProps {
	options: FlowOptions;
	offers: Offer[];
	onOfferSelected?: (offer: Offer) => void;
	onCancel?: () => void;
	onEditText?: (keys: PhraseKey[]) => void;
	hideCancel?: boolean;
}

export const OptionsSlide: React.FunctionComponent<OptionsSlideProps> = ({ options, offers, onOfferSelected, onCancel, onEditText, hideCancel }) => {
	return <div style={{ display: 'flex', flexDirection: 'column' }}>
		{hideCancel !== true && <div style={SlideStyle}>
			<MarkdownViewer onClick={onEditText === undefined ? undefined : () => onEditText(['welcomeMessage'])} className={"editable"}>{options.getText('welcomeMessage')}</MarkdownViewer>
		</div>}
		{offers.map(offer => <div style={{ display: 'flex' }} key={offer.id}>
			<OptionButton key={offer.id} option={offer} style={SlideStyle} onClick={onOfferSelected ? () => onOfferSelected(offer) : () => { }} />
		</div>)}
		{hideCancel !== true && <div style={{ display: 'flex' }}>
			<OptionButton option={{ name: options.getText('cancelConfirmationTitle') ?? "", details: options.getText('cancelConfirmationDetails') ?? "" }}
				style={SlideStyle} onClick={onEditText ? () => onEditText(["cancelConfirmationTitle", "cancelConfirmationDetails"]) : onCancel} className={"editable"} />
		</div>}
	</div>;
}