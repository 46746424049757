import { Form, ProgressButton } from '@cassini-app/react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme } from '@material-ui/core';
import { useSavePhrase } from 'Api/Settings';
import { MarkdownViewer } from 'CancelWidget/MarkdownViewer';
import { Phrase, PhraseKey } from 'CancelWidget/types';
import React from 'react';
import { useImmer } from 'use-immer';

export interface FieldDefinition {
	id: PhraseKey;
	type: 'plain' | 'markdown';
	label: string;
}

interface UpdateTextDialogProps {
	templates: { phrase: Phrase, field: FieldDefinition }[];
	open: boolean;
	onClose: () => void;
}

export const UpdateTextDialog: React.FunctionComponent<UpdateTextDialogProps> = ({ templates: originalTemplates, open, onClose }) => {
	const [templates, updateTemplates] = useImmer(originalTemplates);
	const theme = useTheme();
	React.useEffect(() => {
		if (open) {
			updateTemplates(() => originalTemplates);
		}
	}, [open, updateTemplates, originalTemplates]);
	const savePhrase = useSavePhrase();

	const changedTemplates = templates.filter(template => {
		const originalTemplate = originalTemplates.find(t => t.phrase.key === template.phrase.key);
		if (originalTemplate && template.phrase.text !== originalTemplate.phrase.text) {
			return true;
		}
		return false;
	});

	const submit = async () => {
		for (const template of changedTemplates) {
			await savePhrase(template.phrase);
		}
		onClose();
	}

	return <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
		<DialogTitle>Update Text</DialogTitle>
		<DialogContent>
			{templates.map(template => {
				const update = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
					updateTemplates(d => {
						const t = d.find(t => t.phrase.key === template.phrase.key);
						if (t) {
							t.phrase.text = e.target.value;
						}
					})
				}
				if (template.field.type === "plain") {
					return <TextField key={template.phrase.key} label={template.field.label} value={template.phrase.text} onChange={update} fullWidth style={{ marginTop: theme.spacing(2) }} />;
				}
				else {
					return <div style={{ marginTop: theme.spacing(2) }} key={template.phrase.key}>
						<TextField label={`${template.field.label} (markdown supported)`} multiline rows={5} value={template.phrase.text} onChange={update} fullWidth />
						<Typography style={{ display: 'block', marginTop: theme.spacing(2) }} variant="caption" color="textSecondary">Markdown Preview</Typography>
						<MarkdownViewer>{template.phrase.text}</MarkdownViewer>
					</div>;
				}
			})}
			<Form onSubmit={submit} initialValues={{}} submitEnabledOnlyIfChanged={false}>
				<DialogActions>
					<Button variant="outlined" onClick={onClose}>Cancel</Button>
					<ProgressButton type="submit" color="primary" disabled={changedTemplates.length === 0}>Save</ProgressButton>
				</DialogActions>
			</Form>
		</DialogContent>
	</Dialog>;
}