import { FormErrorMessage, FormIf, FormSelect } from '@cassini-app/react';
import { Button, Divider, IconButton, MenuItem, Typography } from '@material-ui/core';
import { Condition, ConditionOperator, ConditionType, Offer, Reason } from 'CancelWidget/types';
import { FieldArray } from 'formik';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { ReactComponent as TrashCan } from '../../images/trashcan.svg';
import { FormChipList } from './FormChipList';
import { PlanChipList } from './PlanChipList';

interface ConditionBuilderProps {
	reasons: Reason[];
	offers: Offer[];
}

export const ConditionBuilder: React.FunctionComponent<ConditionBuilderProps> = ({ reasons, offers }) => {
	return <div>
		<FieldArray name="conditions" render={arrayHelpers => {
			const conditions: Condition[] | null = arrayHelpers.form.values.conditions;
			return <>
				{conditions && conditions.map((c, i) => <>
					<div key={c.id} style={{ display: 'flex', flexWrap: 'wrap' }}>
						<IconButton color="secondary" style={{ alignSelf: 'flex-start', marginTop: 8 }} onClick={() => arrayHelpers.remove(i)}><TrashCan /></IconButton>
						<FormSelect name={`conditions[${i}].type`} variant="outlined" style={{ flex: '1 1 170px' }}>
							<MenuItem value={ConditionType.Reason}>Selected Reason</MenuItem>
							<MenuItem value={ConditionType.Plan}>Current Plan</MenuItem>
							<MenuItem value={ConditionType.Offer}>Previously Applied Offer</MenuItem>
						</FormSelect>
						<FormErrorMessage name={`conditions[${i}].type`} />
						<FormSelect name={`conditions[${i}].operator`} variant="outlined" style={{ flex: '1 1 150px' }}>
							<MenuItem value={ConditionOperator.Require}>Must be one of</MenuItem>
							<MenuItem value={ConditionOperator.Exclude}>Must not be one of</MenuItem>
						</FormSelect>
						<FormErrorMessage name={`conditions[${i}].operator`} />
						<FormIf value={ConditionType.Reason} name={`conditions[${i}].type`}>
							<FormChipList name={`conditions[${i}].ids`} options={reasons.map(r => ({ label: r.name, value: r.id }))} addLabel={"Reasons For Cancellation"} style={{ flex: '1 1 500px' }} />
							<FormErrorMessage name={`conditions[${i}].ids`} />
						</FormIf>
						<FormIf value={ConditionType.Plan} name={`conditions[${i}].type`}>
							<PlanChipList name={`conditions[${i}].ids`} />
							<FormErrorMessage name={`conditions[${i}].ids`} />
						</FormIf>
						<FormIf value={ConditionType.Offer} name={`conditions[${i}].type`}>
							<FormChipList name={`conditions[${i}].ids`} options={offers.map(o => ({ label: o.name, value: o.id }))} addLabel={"Offers"} />
							<FormErrorMessage name={`conditions[${i}].ids`} />
						</FormIf>
					</div>
					<Divider style={{ margin: '2px 0' }} />
				</>)}
				{(conditions?.length ?? 0) === 0 && <Typography color="textSecondary" style={{ fontStyle: "italic", marginTop: 20 }}>No Conditions Created Yet</Typography>}
				<Button variant="outlined" color="primary" style={{ marginTop: 10 }} onClick={() => arrayHelpers.push({ id: uuid(), type: ConditionType.Reason, operator: ConditionOperator.Require } as Condition)}>Add Condition</Button>
			</>
		}} />
	</div>;
}