import { ProgressButton } from '@cassini-app/react';
import React from 'react';
import { MarkdownViewer } from './MarkdownViewer';
import { SlideStyle } from './Slide';

interface ConfirmationSlideProps {
	confirmationMessage?: string;
	onLinkClicked?: () => void;
	confirmationButtonText?: string;
	onButtonClick?: () => void;
}

export const ConfirmationSlide: React.FunctionComponent<ConfirmationSlideProps> = ({ confirmationMessage, onLinkClicked, confirmationButtonText, onButtonClick }) => {
	return <div style={SlideStyle}>
		<MarkdownViewer onLinkClicked={onLinkClicked}>{confirmationMessage}</MarkdownViewer>
		{confirmationButtonText && <ProgressButton color="primary" fullWidth onClick={onButtonClick} className="editable">{confirmationButtonText}</ProgressButton>}
	</div>;
}