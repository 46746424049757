import { useSessionApi } from '@cassini-app/react';
import { CircularProgress, Container, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as QuitingTimeIcon } from '../Icons/quitting_time.svg';

interface UserSignOutProps {

}

export const UserSignOut: React.FunctionComponent<UserSignOutProps> = () => {
	const theme = useTheme();
	const { signOut: signOutOfSession } = useSessionApi();
	const history = useHistory();
	React.useEffect(() => {
		const signOut = async () => {
			await signOutOfSession();
			history.push("/login")
		}
		signOut();
	}, [history, signOutOfSession]);

	return <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
		<div style={{ display: 'flex' }}>
			<Typography style={{ margin: theme.spacing(1) }}>Signing Out</Typography>
			<CircularProgress size={20} style={{ margin: theme.spacing(1) }} />
		</div>
		<QuitingTimeIcon style={{ maxHeight: 300 }} />
	</Container>;
}