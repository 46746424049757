
export interface Message<T = any> {
	message: string;
	args?: T
}

export function parseMessage<T>(value: string): Message<T> {
	return JSON.parse(value);
}

export function getMessagePassingOrigin() {
	const queryString = new URLSearchParams(window.location.search);
	return queryString.get("origin") ?? "";
}

export function sendMessage<T extends {}>(message: Message<T>) {
	const origin = getMessagePassingOrigin();
	if (origin !== "") {
		globalThis.parent.postMessage(JSON.stringify(message), origin);
	}
}