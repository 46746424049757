import { useTheme } from '@material-ui/core';
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import React from 'react';
import './Calendar.css';
import './DateRangePicker.css';

interface DateRangePickerProps {
	value: Date[];
	onChange: (dates: Date[]) => void;
}

export const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = ({ value, onChange }) => {
	const theme = useTheme();
	return <div className={theme.palette.type}>
		<ReactDateRangePicker value={value} onChange={onChange} calendarType="US" clearIcon={null} maxDate={new Date()} />
	</div>;
}