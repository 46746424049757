import { useIsDown } from '@cassini-app/react';
import { useTheme } from '@material-ui/core';
import { ResponsiveLine } from '@nivo/line';
import React from 'react';
import { SliceTooltip } from './SliceTooltip';
import { useNivoTheme } from './UseNivoTheme';

export interface Line {
	id: string;
	color: string;
	data: {
		x: string;
		y: number;
	}[];
}

export interface LineChartProps {
	lines: Line[];
}

const dateFormatter = new Intl.DateTimeFormat(undefined, { month: 'short', day: 'numeric' });

export const LineChart: React.FunctionComponent<LineChartProps> = ({ lines }) => {
	const isSmall = useIsDown('sm');
	const isMedium = useIsDown('md')
	const suggestedTickCount = isMedium ? (isSmall ? 3 : 6) : 12;
	const theme = useTheme();
	const nivoTheme = useNivoTheme();

	return <ResponsiveLine
		data={lines}
		margin={{ top: 10, right: 30, bottom: 30, left: 50 }}
		xScale={{ format: "%Y-%m-%dT%H:%M:%S", type: "time", useUTC: false }}
		xFormat={(value: any) => dateFormatter.format(value)}
		curve="monotoneX"
		theme={nivoTheme}
		axisBottom={{
			tickValues: Math.min(lines.reduce((prev, cur) => Math.max(prev, cur.data.length), 0), suggestedTickCount),
			tickRotation: 0,
			format: "%b %d",
		}}
		axisLeft={{
			tickValues: 6
		}}
		enableGridX={false}
		enableGridY={false}
		enableArea={true}
		colors={value => value.color}
		lineWidth={3}
		pointSize={8}
		pointColor={theme.palette.background.paper}
		pointBorderWidth={2}
		pointBorderColor={{ from: "serieColor" }}
		useMesh={true}
		areaOpacity={0}
		enableSlices="x"
		sliceTooltip={SliceTooltip}
	/>;
}