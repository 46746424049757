import { Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { MarkdownViewer } from './MarkdownViewer';

interface OfferViewProps {
	title: string;
	details: string;
}

export const OfferView: React.FunctionComponent<OfferViewProps> = ({ title, details }) => {
	const theme = useTheme();
	return <div style={{ flex: 1, textAlign: 'left', textTransform: 'none', minHeight: 48, display: 'flex', flexDirection: 'column', justifyContent: 'center', color: theme.palette.text.primary }}>
		<Typography color="primary">{title}</Typography>
		<MarkdownViewer>{details}</MarkdownViewer>
	</div>;
}