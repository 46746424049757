import { Container, useTheme } from '@material-ui/core';
import { DateRangePicker } from 'Components/DateRangePicker/DateRangePicker';
import { DashboardPage } from 'Dashboard/DashboardPage';
import { HistoryPage } from 'History/HistoryPage';
import React from 'react';
import { Route } from 'react-router-dom';
import { getInitialDateRange } from 'Utils/getInitialDateRange';

interface DateRangePagesProps {

}

export const DateRangePages: React.FunctionComponent<DateRangePagesProps> = () => {
	const initialDates = React.useMemo(() => getInitialDateRange(), []);
	const [selectedDateRange, setSelectedDateRange] = React.useState(initialDates);
	const theme = useTheme();
	return <Container style={{ marginBottom: theme.spacing(2) }}>
		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<DateRangePicker value={selectedDateRange} onChange={setSelectedDateRange} />
		</div>
		<Route exact path="/"><DashboardPage startDate={selectedDateRange[0]} endDate={selectedDateRange[1]} /></Route>
		<Route path="/history"><HistoryPage startDate={selectedDateRange[0]} endDate={selectedDateRange[1]} /></Route>
	</Container>
}