import { Button, useTheme } from '@material-ui/core';
import React from 'react';
import { OfferView } from './OfferView';
import { Option } from './types';

interface OptionButtonProps {
	option: Option
	style?: React.CSSProperties;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	disabled?: boolean;
	showEnabledToggle?: boolean;
	className?: string;
}

export const OptionButton: React.FunctionComponent<OptionButtonProps> = ({ option, style, onClick, disabled, className }) => {
	const theme = useTheme();
	return <Button disabled={disabled} style={{ backgroundColor: theme.palette.background.default, ...style }} fullWidth variant="outlined" onClick={onClick} className={className}>
		<OfferView title={option.name} details={option.details} />
	</Button>;
}