import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { ApiKey } from 'CancelWidget/types';
import { Code } from 'Components/Code';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';

const columns: MUIDataTableColumnDef[] = [
	{ label: "Name", name: "name" },
	{ label: "Required", name: "required" },
	{ label: "Description", name: "description" }
];

interface CustomIntegrationProps {
	stripeKeys: ApiKey[]
}

export const CustomIntegration: React.FunctionComponent<CustomIntegrationProps> = ({ stripeKeys }) => {
	const spacing: React.CSSProperties = { margin: '10px 0' };

	const propertyData: { name: string, required: string, description: string }[] = [
		{ name: "subscriptionId", required: "Yes", description: "The customer's Stripe subscription id." },
		{ name: "apiKey", required: "Yes", description: `Your api key: ${stripeKeys[0]?.id ?? ""}.` },
		{ name: "mode", required: "No", description: "During testing you can set the mode to test. While in test mode no changes will be made to the subscription and no results will be saved." },
		{ name: "colorMode", required: "No", description: "You can pass \"light\" or \"dark\" to override the default color mode for your flow." }
	]

	return <>
		<Card style={spacing}>
			<CardHeader title="Add the SDK" />
			<CardContent>
				<Typography>{`First add the product shuttle script in your site before the </body> tag in the pages where your customers will cancel their subscription.`}</Typography>
				<Code>{`<script src="https://app.productshuttle.com/widget/productshuttle.js" type="text/javascript"></script>`}</Code>
			</CardContent>
		</Card>
		<Card style={spacing}>
			<CardHeader title="Start the cancellation flow" />
			<CardContent>
				<Typography>The code below should be triggered whenever your customers click on your cancellation button. Make sure to replace <pre style={{ display: 'inline' }}>CUSTOMER_SUBSCRIPTION_ID</pre> with the customers actual subscription id from Stripe. See the configuration options below for additional values you can pass to control the flow.</Typography>
				<Code>{`productShuttle.openCancellationDialog({\n\tsubscriptionId: 'CUSTOMER_SUBSCRIPTION_ID', \n\tapiKey: '${stripeKeys[0].id}'\n});`}</Code>
				{stripeKeys.length > 1 && <>
					<Typography>Other Api Keys</Typography>
					{stripeKeys.slice(1).map(key => <Typography>{key}</Typography>)}
				</>}
				<Typography>The function returns a promise with information about what the customer did (see below).</Typography>
			</CardContent>
		</Card>
		<Card style={spacing}>
			<CardHeader title="Configuration Object" />
			<CardContent>
				<Typography>The object sent should have the following shape.</Typography>
				<Code>{`{\n\tsubscriptionId: string\n\tapiKey: string\n\tmode?: 'test'\n\tcolorMode?: 'light' | 'dark'\n}`}</Code>
			</CardContent>
		</Card>
		<div style={spacing}>
			<MUIDataTable title="Configuration Object Properties" columns={columns} data={propertyData} options={{ search: false, download: false, filter: false, viewColumns: false, print: false, selectableRows: "none", pagination: false }} />
		</div>
		<Card style={spacing}>
			<CardHeader title="Promise Result" />
			<CardContent>
				<Typography>The response has a status property to indicate the final outcome.</Typography>
				<Code>{`{\n\tstatus: 'incomplete' | 'saved' | 'cancelled'\n}`}</Code>
			</CardContent>
		</Card>
	</>;
}