import { Form, ProgressButton } from '@cassini-app/react';
import { Button, Card, CardActionArea, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { PlanTypes, useActivePlan, useActivePlanMutation } from 'Api/Plans';
import React from 'react';

interface PlanPageProps {

}

type ChangeType = 'upgrade' | 'downgrade' | 'subscribe';

export const PlanPage: React.FunctionComponent<PlanPageProps> = () => {
	const activePlan = useActivePlan();
	const setActivePlan = useActivePlanMutation();
	const [planType, setPlanType] = React.useState<PlanTypes | null | undefined>(undefined);
	const [newPlan, setNewPlan] = React.useState<{ type: PlanTypes | undefined, change: ChangeType }>({ type: undefined, change: 'upgrade' });
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const theme = useTheme();
	const cardStyle: React.CSSProperties = { flex: '0 1 500px', margin: theme.spacing(2) }
	const highlightedStyle: React.CSSProperties = { boxShadow: `${theme.palette.primary.main} 0px 0px 13px 5px`, color: theme.palette.text.primary };
	const nonHighlightedStyle: React.CSSProperties = { color: theme.palette.text.hint };

	const hasActivePlan = activePlan.data?.planType !== null;

	React.useEffect(() => {
		setPlanType(activePlan.data?.planType);
	}, [activePlan.data, setPlanType]);

	const submit = async () => {
		if (newPlan.type !== undefined) {
			await setActivePlan(newPlan.type);
		}
	}

	const updateNewPlan = () => {
		if (planType !== null) {
			let change: ChangeType = 'subscribe';
			if (hasActivePlan) {
				change = planType === PlanTypes.Monthly ? 'upgrade' : 'downgrade';
			}
			setNewPlan({ type: planType, change });
			setDialogOpen(true);
		}
	}

	return <Container style={{ marginBottom: theme.spacing(2) }}>
		<div style={{ display: 'flex', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center' }}>
			<Card style={{ ...cardStyle, ...(planType === PlanTypes.PayAsYouGo ? highlightedStyle : nonHighlightedStyle) }}>
				{planType !== undefined && <CardActionArea onClick={() => setPlanType(PlanTypes.PayAsYouGo)} style={{ height: '100%' }}>
					<CardHeader title={<Typography variant="h3" >No Risk</Typography>} />
					<CardContent>
						<Typography>No Monthly Fee</Typography>
						<Typography>Unlimited Users</Typography>
						<Typography>Unlimited Cancellations</Typography>
					</CardContent>
					<CardContent>
						<Typography variant="h3">$0 / month</Typography>
						<Typography>30% of first month's saved revenue</Typography>
					</CardContent>
				</CardActionArea>}
				{planType === undefined && <Skeleton style={{ height: 300, transform: "scale(1)" }} />}
			</Card>
			<Card style={{ ...cardStyle, ...(planType === PlanTypes.Monthly ? highlightedStyle : nonHighlightedStyle) }}>
				{planType !== undefined && <CardActionArea onClick={() => setPlanType(PlanTypes.Monthly)} style={{ height: '100%' }}>
					<CardHeader title={<Typography variant="h3" >Small Business</Typography>} />
					<CardContent>
						<Typography>Flat Fee</Typography>
						<Typography>Unlimited Users</Typography>
						<Typography>1000 Cancellations / month</Typography>
					</CardContent>
					<CardContent>
						<Typography variant="h3">$50 / month</Typography>
					</CardContent>
				</CardActionArea>}
				{planType === undefined && <Skeleton style={{ height: 300, transform: "scale(1)" }} />}
			</Card>
		</div>
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Button disabled={activePlan.data?.planType === planType} color="primary" onClick={updateNewPlan}>{hasActivePlan ? "Update Plan" : "Subscribe"}</Button>
		</div>
		<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
			<DialogTitle>{newPlan.change === 'subscribe' ? "Confirm Subscription" : "Confirm Plan Change"}</DialogTitle>
			<DialogContent>
				<ul>
					{newPlan.change === 'upgrade' && <>
						<li><Typography>Your plan will be switched immediately.</Typography></li>
						<li><Typography>You will be charged a prorated amount for the remainder of your billing month.</Typography></li>
						<li><Typography>Any recovered revenue up to this point will still be charged at your current plan's rate.</Typography></li>
					</>}
					{newPlan.change === 'downgrade' && <>
						<li><Typography>Your plan will be switched at the end of your current billing period. </Typography></li>
					</>}
					{newPlan.change === 'subscribe' && <>
						<li><Typography>Your new subscription will be started immediately.</Typography></li>
					</>}
				</ul>
			</DialogContent>
			<Form initialValues={{}} onSubmit={submit} submitEnabledOnlyIfChanged={false} onSuccess={() => setDialogOpen(false)}>
				<DialogActions>
					<Button variant="outlined" onClick={() => setDialogOpen(false)}>Cancel</Button>
					<ProgressButton fullWidth={false} type="submit" color="primary">{newPlan.change === 'subscribe' ? "Subscribe" : "Update"}</ProgressButton>
				</DialogActions>
			</Form>
		</Dialog>
	</Container>;
}