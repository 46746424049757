import { useQueryString } from '@cassini-app/react';
import { Card, CardActionArea, CardContent, CardHeader, SvgIcon, Typography, useTheme } from '@material-ui/core';
import { ApiKey } from 'CancelWidget/types';
import { BubbleImage } from 'images/BubbleImage';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CustomIcon } from '../Icons/htmlPc.svg';
import { BubbleIntegration } from './BubbleIntegration';
import { CustomIntegration } from './CustomIntegration';

interface HowToIntegrateProps {
	stripeKeys: ApiKey[];
}

interface IntegrationDef {
	title: string;
	location: string;
	image: React.ReactNode,
	label: string;
}

const integrations: IntegrationDef[] = [
	{
		title: "Custom Html Integration",
		location: `/integrations/website?type=custom`,
		image: <SvgIcon style={{ width: 200, height: 200 }} ><CustomIcon /></SvgIcon >,
		label: "A little code"
	},
	{
		title: "Bubble.io Integration",
		location: `/integrations/website?type=bubble`,
		image: <BubbleImage style={{ width: '100%', maxWidth: 200 }} />,
		label: "No Code"
	}
]

export const HowToIntegrate: React.FunctionComponent<HowToIntegrateProps> = ({ stripeKeys }) => {
	const queryType = useQueryString().get("type");
	const type: "custom" | "bubble" | "pick" = queryType === "custom" || queryType === "bubble" ? queryType : "pick";
	const history = useHistory();
	const theme = useTheme();

	return <>
		{type === "custom" && <>
			<CustomIntegration stripeKeys={stripeKeys} />
		</>}
		{type === "bubble" && <>
			<BubbleIntegration keys={stripeKeys} />
		</>}
		{type === "pick" && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Typography variant="h2">Choose your integration</Typography>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{integrations.map(integration => <Card style={{ margin: theme.spacing(2), width: 300, height: 320, justifyContent: 'center' }}>
					<CardActionArea onClick={() => history.push(integration.location)} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
						<CardHeader title={integration.title} />
						<CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
							<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
								{integration.image}
							</div>
							<Typography color="primary">{integration.label}</Typography>
						</CardContent>
					</CardActionArea>
				</Card>)}
			</div>
		</div>}
	</>;
}