import { FormSelect } from '@cassini-app/react';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getPlans } from 'Api/Plans';
import React from 'react';
import { useQuery } from 'react-query';

interface SelectPlanProps {
	name: string;
	label?: string;
	selectStyle?: React.CSSProperties
}

export const SelectPlan: React.FunctionComponent<SelectPlanProps> = ({ name, label, selectStyle }) => {
	const plansQuery = useQuery('plans', getPlans);
	const plans = plansQuery.data;
	if (plansQuery.isFetching) {
		return <Skeleton style={{ height: 50, transform: "scale(1, 0.9)" }} />;
	}
	else if (plans === undefined) {
		return <Typography color="error" style={{ display: 'flex', alignItems: 'center' }}>Unable to load plans.</Typography>
	}
	return <FormSelect name={name} label={label ?? "Plan Id"} options={plans.map(p => ({ label: p.name, value: p.id }))} style={selectStyle} />;
}