import { Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface SingleInfoCardProps {
	value: number | string | undefined;
	line1: string;
	line2?: string;
	to: string;
}

const cardStyle: React.CSSProperties = { flex: `1 1 max(125px, calc(20% - 10px))`, margin: 5 };

export const SingleInfoCard: React.FunctionComponent<SingleInfoCardProps> = ({ value, line1, line2, to }) => {
	const history = useHistory();
	return <Card style={cardStyle} key={line1}>
		<CardActionArea onClick={() => history.push(to)} style={{ height: '100%' }}>
			{value !== undefined &&
				<CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

					<Typography variant="h4">{value}</Typography>
					<Typography variant="caption">{line1}</Typography>
					{line2 && <Typography variant="caption">{line2}</Typography>}
				</CardContent>
			}
			{value === undefined && <Skeleton variant="rect" style={{ width: '100%', height: '100%', minHeight: 92 }} />}
		</CardActionArea>
	</Card>;
}