import { TwoFactorCard, UpdateEmailCard, UpdatePasswordCard, useCurrentUser } from '@cassini-app/react';
import { Container, Divider, Typography, useTheme } from '@material-ui/core';
import { PlanPage } from 'PlanPage';
import React from 'react';
import { ManageBilling } from './ManageBilling';

const Account: React.FunctionComponent<{}> = () => {
	const flex = '1 1 max(300px, calc(25% - 20px))';
	const theme = useTheme();
	const user = useCurrentUser();
	const isSiteAdmin = user.data?.roles.includes("site_admin");

	return <>
		<Container>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				<UpdateEmailCard style={{ flex, margin: '20px 10px' }} />
				<UpdatePasswordCard style={{ flex, margin: '20px 10px' }} />
				<TwoFactorCard style={{ flex }} />
				{isSiteAdmin === false && <ManageBilling />}
				<div style={{ flex, margin: '20px 10px', height: 0 }}></div>
				<div style={{ flex, margin: '20px 10px', height: 0 }}></div>

			</div>
		</Container>
		{isSiteAdmin === false && <>
			<Divider style={{ margin: `${theme.spacing(4)}px 0` }} />
			<Typography variant="h4" style={{ textAlign: "center" }}>Plans</Typography>
			<PlanPage />
		</>}
	</>;
}

export default Account