import Axios from "axios";
import { Offer } from "CancelWidget/types";
import produce from "immer";
import { useQuery, useQueryClient } from "react-query";

export function useSaveOffer() {
	const queryClient = useQueryClient();
	return async (offers: Offer[]) => {
		await Axios.post(`v1/offers`, { offers });
		let cachedOffers = queryClient.getQueryData<Offer[]>('offers');
		const updatedOffers = produce(cachedOffers, updatedOffers => {
			updatedOffers = updatedOffers ?? [];
			for (const offer of offers) {
				const index = updatedOffers.findIndex(o => o.id === offer.id);
				if (index >= 0) {
					updatedOffers[index] = offer;
				}
				else {
					updatedOffers.push(offer);
				}
			}
		});
		queryClient.setQueryData('offers', updatedOffers);
	}
}

async function getOffers() {
	const response = await Axios.get<{ offers: Offer[] }>(`offers`);
	return response.data.offers;
}

export function useOffers() {
	return useQuery('offers', getOffers);
}

export function useUpdateEnabledOffers() {
	const queryClient = useQueryClient();
	return async (offers: { id: string, enabled: boolean }[]) => {
		await Axios.post(`offers/enabled`, { offers });
		queryClient.setQueryData('offers', produce(queryClient.getQueryData<Offer[]>('offers'), cachedOffers => {
			if (cachedOffers) {
				for (const updatedOffer of offers) {
					const offer = cachedOffers.find(o => o.id === updatedOffer.id);
					if (offer) {
						offer.enabled = updatedOffer.enabled
					}
				}
			}
		}))
	}
}