import { useTheme } from "@material-ui/core";

export function useNivoTheme() {
	const theme = useTheme();
	return {
		fontSize: 14,
		tooltip: {
			container: {
				backgroundColor: theme.palette.background.default,
				color: theme.palette.text.primary
			}
		},
		legends: {
			text: {
				fill: theme.palette.text.primary,
				color: theme.palette.primary.main
			}
		},
		axis: {
			legend: {
				text: {
					fill: theme.palette.text.primary,
				}
			},
			ticks: {
				text: {
					fill: theme.palette.text.primary
				},
				line: {

				}
			}
		},
		crosshair: {
			line: {
				stroke: theme.palette.text.primary
			}
		},
		grid: {
			line: {
				stroke: theme.palette.primary.main
			}
		}
	};
}