import Axios from "axios";
import { Interaction, SavedRevenue } from "CancelWidget/types";
import { useQuery } from "react-query";

function formatDate(date: Date) {
	return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

async function getSummaryReport(start: Date, end: Date) {
	const response = await Axios.get<{ interactions: Interaction[], savedRevenues: SavedRevenue[] }>(`reports/summary?start=${formatDate(start)}&end=${formatDate(end)}`);
	return response.data;
}

export function useSummaryReport(start: Date, end: Date) {
	return useQuery(["reports", "summary", start, end], () => getSummaryReport(start, end), {
		refetchOnMount: true
	});
}