import Axios from "axios";
import { ApiKey } from "CancelWidget/types";
import { useQuery, useQueryClient } from "react-query";

export function useConnectStripeAccount() {
	const queryClient = useQueryClient();
	return async (code: string) => {
		await Axios.post("integrations/stripe/connect", { code });
		queryClient.resetQueries("apiKeys");
	}
}

async function getApiKeys() {
	const response = await Axios.get<{ keys: ApiKey[] }>("integrations/apikeys");
	return response.data.keys;
}

export function useApiKeys() {
	return useQuery("apiKeys", getApiKeys);
}